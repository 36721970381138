import { Form, Row } from 'antd';
import React from 'react';

import Button from '@motokiki/shared/button';
import Input from '@motokiki/shared/input';
import Label from '@motokiki/shared/label';
import LoadingModal from '@motokiki/shared/components/LoadingModal';

import './stapletonsIDScene.scss';

import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import NavLocation from '../../components/navLocation';
import partnerService from '../../services/partner';
import setLocation from '../../methods/setLocation';

type Props = {
  history: {
    push: (url: string) => void
  }
};

type State = {
  auth0profile: undefined
};

class StapletonsIDScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const auth0profile = auth0Client.getProfile();
    this.state = { auth0profile, showHome: false };
  }

  componentDidMount() {
    this.locationCheck();
  }

  handleSubmit = async (e: Event) => {
    e.preventDefault();
    const { history } = this.props;
    const { auth0profile, stapletonsID } = this.state;
    const haveWeCreatedPartner = await partnerService.create(
      stapletonsID,
      auth0profile.sub
    );
    if (haveWeCreatedPartner) {
      history.push({
        pathname: '/signUp',
        state: { stapletonsID, auth0ID: auth0profile.sub }
      });
    } else {
      history.push(`/StapletonsIDScene`);
    }
  };

  handleInput = (event, name) => {
    const { value } = event.target;
    this.setState({ [`${name}`]: value });
  };

  locationCheck = async () => {
    const { history } = this.props;
    const { auth0profile } = this.state;
    const auth0ID = auth0profile && auth0profile.sub;
    const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
    setLocation(history.location.pathname, showHome[1], history);
    this.setState({ showHome: !!showHome });
  };

  render() {
    const { showHome } = this.state;
    return (
      <div>
        {NavLocation('Partner Registration - Stapletons ID')}
        {showHome ? (
          <div className="stapletonsBody">
            <h2
              style={{
                width: '100%',
                marginBottom: '30px'
              }}
            >
              To access Partnerkiki, Please enter your stapletons ID (optional)
            </h2>
            <div className="idForm">
              <Form onSubmit={this.handleSubmit} className="form">
                <Row>
                  <Label>
                    Stapleton&apos;s account number
                    <Input
                      size="large"
                      placeholder="abc123"
                      onChange={e => this.handleInput(e, 'stapletonsID')}
                    />
                  </Label>
                  <Button style={{ marginTop: '20px' }} type="submit">
                    Continue →
                  </Button>
                </Row>
                <h3 style={{ width: '100%', margin: '30px 0' }}>
                  Dont have a stapletons account ID? Just leave it blank
                </h3>
              </Form>
            </div>
            <div className="additionalInfo">
              <h3> Additional info</h3>
              <a href="/faqs"> FAQs </a>
              <a href="/charter"> Motokiki partner charter</a>
            </div>
          </div>
        ) : (
          <LoadingModal loadingText="Getting your profile..." />
        )}
      </div>
    );
  }
}

export default Form.create()(StapletonsIDScene);