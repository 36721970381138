/* @flow */

// Standard Stuff
import * as React from 'react';
import DocumentTitle from 'react-document-title';

import 'babel-polyfill';

// React Router
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import queryString from 'query-string';
// Layouts
import MainLayout from './layouts/MainLayout';

// Scenes
import StapletonsIDScene from './scenes/StapletonsIDScene';
import StripeSignUp from './scenes/StripeSignUp';
import ManageFittersScene from './scenes/ManageFittersScene';
import ManagePartnerScene from './scenes/ManagePartnerScene';
import TermsAndConditionsScene from './scenes/TermsAndConditionsScene';
import PartnerAgreementScene from './scenes/PartnerAgreementScene';
import CreatePartnerScene from './scenes/CreatePartnerScene'
import CreateFittersScene from './scenes/CreateFittersScene';
// Components
import Callback from './components/Callback';
import Home from './scenes/Home';

type LayoutManagerProps = {
  history: {
    location: {
      pathname: string,
      search: string
    }
  },
  location: {
    search: string
  },
  children: React.Node,
  StapletonsID: string,
  auth0ID: string
};

const LayoutManager = (props: LayoutManagerProps) => {
  const { children } = props;
  // eslint-disable-next-line react/no-children-prop
  return <MainLayout {...props} children={children} />;
};

const Routes = (
  <DocumentTitle title="Motokiki - Partner">
    <BrowserRouter>
      <Switch>
        <Route exact path="/callback" component={Callback} />
        <Route
          path="/"
          render={props => {
            return (
            <LayoutManager {...props}>
              <Route path="/StapletonsIDScene" component={StapletonsIDScene} />
              <Route path="/stripe-signup" component={StripeSignUp} />
              <Route path="/manage-fitters" component={ManageFittersScene} />
              <Route path="/manage-partner" component={ManagePartnerScene} />
              <Route path="/terms-and-conditions" component={TermsAndConditionsScene} />
              <Route path="/partner-agreement" component={PartnerAgreementScene} />
              <Route path="/create-fitting-centres" component={CreateFittersScene} />
              <Route
                path="/signup"
                render={nprops => (
                  <CreatePartnerScene name={nprops} {...props}>
                    {queryString.parse(props.location.search).error
                      ? queryString.parse(props.location.search)
                          .error_description
                      : 'well done'}
                  </CreatePartnerScene>
                )}
              />
              <Route exact path="/" render={() => <Home {...props} />} />
            </LayoutManager>
          )}}
        />
      </Switch>
    </BrowserRouter>
  </DocumentTitle>
);

export default Routes;