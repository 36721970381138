/* @flow */
import { message } from 'antd';

import api from './api';

const network = {
  getNetwork: async (networkName: String): Promise<Object> => {
    if (!networkName) {
      throw new Error('Missing networkName');
    }
    try {
      const res = await api.get(`/networks/getNetwork/${networkName}`);
      return res;
    } catch (error) {
      message.error('Error getting network');
      console.error('e', error.response);
      throw (error);
    }
  },
  getNetworkById: async (networkID: String): Promise<Object> => {
    if (!networkID) {
      throw new Error('Missing networkID');
    }
    try {
      const res = await api.get(`/networks/getNetworkById/${networkID}`);
      return res;
    } catch (error) {
      message.error('Error getting network');
      console.error('e', error.response);
      throw (error);
    }
  },
};

export default network;