/* eslint-disable no-restricted-syntax */
/* @flow */
import mongoose from 'mongoose';

// formats booking table data for the database

const formatDataForDB = (dataSource: any, bookingSlots: any) => {
  console.log("ffDB dataSource:", dataSource)
  console.log('ffDB bookingSlots', bookingSlots)
  const slots = [];
  if (dataSource.length > 0) {
    dataSource.forEach(item => {
      if (bookingSlots[item.TimeSlot]) {
        for (const [key, value] of Object.entries(
          bookingSlots[item.TimeSlot]
        )) {
          console.log("forLoop key", key)
          console.log("forLoop value", value)
          const obj = slots.find(
            o => o.label === item.TimeSlot && o.maxNumber === value && o.days
          );
          if (obj) {
            console.log("if block run")
            obj.days.push(key);
          } else {
            console.log("else block run")
            slots.push({
              _id: mongoose.Types.ObjectId(),
              days: [key],
              label: item.TimeSlot,
              maxNumber: value,
              startTime: item.startTime.format('HH:mm'),
              endTime: item.endTime.format('HH:mm')
            });
          }
        }
      }
    });
  }

  console.log("slots on exit", slots)
  return slots;
};

export default formatDataForDB;
