/* eslint-disable no-param-reassign */
import ReactDOM from 'react-dom';
import routes from './routes';
import '@motokiki/shared/styles/global.scss';

// $FlowFixMe
import './theme.scss';

const { registerObserver } = require('react-perf-devtool');

const callback = measures => {
  measures.map(m => {
    const keys = Object.keys(m);
    keys.map(key => {
      m[key] = m[key].numberOfTimes ? m[key].numberOfTimes : m[key];
      return m[key];
    });
    return m;
  });
};

registerObserver({}, callback);
ReactDOM.render(routes, document.getElementById('root'));
