/* @flow */

// import { Button } from 'antd';
import Button from '@motokiki/shared/button';
import React from 'react';
import '../../styles/colors.scss'

import auth0Client from '../../Auth';
import NavLocation from '../../components/navLocation';

const LoginScene = () => (
  <div style={{ backgroundColor: '#f5f5f9', minHeight: `calc(100vh - 174px)` }}>
    {NavLocation('Partner registration portal')}
    <div
      style={{
        padding: '40px',
        borderRadius: '5px',
        maxWidth: '400px',
        margin: '40px auto',
        backgroundColor: 'white',
        textAlign: 'center'
      }}
    >
      <img
        style={{ height: '60px', paddingBottom: '20px' }}
        alt="Motokiki Logo"
        src='https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/MK_logo.svg'
      />
      <div>
        <h2>Sign in or sign up </h2>
        <p style={{ marginBottom: '20px' }}>
          To register a your business and fitting centre(s), you’ll need to sign in. You can sign in with a google account, or create an account with an email and password.
        </p>
        <Button
          onClick={auth0Client.signIn}
          className={'small rebrand-primary'}
        >
          Sign in securely
        </Button>
      </div>
    </div>
  </div>
);

export default LoginScene;
