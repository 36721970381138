/* @flow */

import * as React from 'react';
import { Avatar, Layout, Popconfirm } from 'antd';
// import { Link } from 'react-router-dom';

import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import { Footer2 } from '../../components/Footer';
import LoginScene from '../../scenes/LoginScene';
// $FlowFixMe
import styles from './MainLayout.module.scss'
import NavBar from '../../components/NavBar';

const { Header } = Layout;

type MainLayoutProps = {
  location: Object,
  history: any,
  children: React.Node
};

type MainLayoutState = {
  auth0profile: Object,
  showHome: string
};

const { REACT_APP_BROCHUREWARE_LINK } = process.env;

class MainLayout extends React.Component<MainLayoutProps, MainLayoutState> {
  state = { auth0profile: false, showHome: '' };

  componentDidMount() {
    this.silentAuth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.updateShowHome()
    }
  }

  updateShowHome = async () => {
    const auth0ID = this.state.auth0profile && this.state.auth0profile.sub;
    const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, '']
    this.setState({
      showHome: showHome[1]
    })
  }

  checkLink() {
    return auth0Client.isAuthenticated() ? "/" : `${REACT_APP_BROCHUREWARE_LINK}`;
  }

  silentAuth = async () => {
    const {
      location: { pathname }
    } = this.props;
    if (pathname === '/callback') {
      return;
    }

    const isauth = await auth0Client.isAuthenticated();
    if (isauth) {
      let { auth0profile } = this.state;
      if (!auth0profile) {
        auth0profile = await auth0Client.getProfile();
        this.setState({ auth0profile });
      }
      const auth0ID = auth0profile && auth0profile.sub;
      const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
      this.setState({ showHome: showHome[1] });
    } else {
      const { history } = this.props;
      history.push(`/`);
    }
  };

  render() {
    const { auth0profile, showHome } = this.state;
    const { children } = this.props;
    return (
      <div>
        <div style={{ backgroundColor: 'white' }} className={styles.desktop}>
          <Header className={styles.headerBar}>
            <div className={styles.header}>
            <a href={this.checkLink()}>
              <img
                src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/MK_logo.svg"
                alt="Motokiki Logo"
                className={styles.motokikiLogo}
              />
            </a>
            <NavBar auth0profile={auth0profile} isAuthenticated={auth0Client.isAuthenticated()} showHome={showHome} signOut={auth0Client.signOut}/>
            </div>
            
          </Header>
        </div>
        <div style={{ backgroundColor: 'white' }} className={styles.mobile}>
          <div>
            <div className={styles.mobileTopLine}>
              <a href={this.checkLink()}>
                <img
                  src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/MK_logo.svg"
                  alt="Motokiki Logo"
                  className={styles.motokikiLogo}
                />
              </a>
              {auth0Client.isAuthenticated() && (
                <div className={styles.userPop}>
                  <Popconfirm
                    placement="bottom"
                    title="Do you want to sign out?"
                    onConfirm={auth0Client.signOut}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Avatar src={auth0profile && auth0profile.picture} />
                  </Popconfirm>
                </div>
              )}
            </div>
          </div>
          <div className={styles.mobileNav}>
            <NavBar auth0profile={auth0profile} isAuthenticated={auth0Client.isAuthenticated()} showHome={showHome} signOut={auth0Client.signOut}/>
          </div>

        </div>
        <div className={styles.mainContentWrapper}>
          {!auth0Client.isAuthenticated() ? <LoginScene /> : <div className={styles.mainContent}>{children}</div>}
        </div>        
        <Footer2 />
      </div>
    );
  }
}

export default MainLayout;