
import React from "react";
import styles from "./NavBar.module.scss";
import { Link } from 'react-router-dom';
import { Menu, Avatar, Popconfirm } from 'antd'
import { BarsOutlined } from '@ant-design/icons'

const { SubMenu } = Menu;

const NavBar = ({auth0profile, showHome, isAuthenticated, signOut}) => {

  return (
    <div className={styles.navBar}>
          {isAuthenticated ? (
            <div>
                  <Menu mode="horizontal" className={`${styles.populatedMenu} ${styles.nonBurger}`}>
                    {showHome === 'signedUp' && (
                      <Menu.Item key="1">
                        <Link to="/">Home</Link>
                      </Menu.Item>
                    )}
                    {showHome === 'signedUp' && (
                      <Menu.Item key="2">
                        <Link to="/manage-partner">Your business</Link>
                      </Menu.Item>
                    )}
                    {showHome === 'signedUp' && (
                      <Menu.Item key="3">
                        <Link to="/manage-fitters">Your fitters</Link>
                      </Menu.Item>
                    )}
                    <Menu.Item className={styles.userPop} key="8">
                      <div>
                        <Popconfirm
                          placement="bottom"
                          title="Do you want to sign out?"
                          onConfirm={signOut}
                          okText="Yes"
                          cancelText="Cancel"
                        >
                          <Avatar src={auth0profile && auth0profile.picture} />
                        </Popconfirm>
                      </div>
                    </Menu.Item>
                  </Menu>
                  
                  {showHome === 'signedUp' && (
                    <div className={styles.mobilenavPadding}>
                      <Menu mode="inline" className={`${styles.populatedMenu} ${styles.burger}`}>
                      <SubMenu key='g1' title={<span><BarsOutlined /></span> }>
                          <Menu.Item key="1">
                            <Link to="/">Home</Link>
                          </Menu.Item>
                          <Menu.Item key="2">
                            <Link to="/manage-partner">Your business</Link>
                          </Menu.Item>
                          <Menu.Item key="3">
                            <Link to="/manage-fitters">Your fitters</Link>
                          </Menu.Item>
                      </SubMenu>
                    </Menu>
                    </div>  
                    

                  )}
            </div>
          ) : (
            <Menu mode="horizontal" className={styles.bareMenu} />
          )}

    </div>
  );
};

export default NavBar;