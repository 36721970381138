
import React from "react";
import styles from "./FitterCard.module.scss";
import FitterCardFront from "../FitterCardFront";
import FitterCardBack from "../FitterCardBack";
import Button from '@motokiki/shared/button';
import { Modal } from 'antd'

import { useState } from "react";

const FitterCard = ({image, fitter, matchParameter, functionOnSelectFitter, activeFitterIdentifier}) => {

  const [isModalVisible, setIsModalVisible] = useState(false)

  const fitterCardFrontJSX =  <div className={styles.front}><FitterCardFront image={image} fitter={fitter} /></div>;

  const fitterCardBackJSX = <div className={styles.back}><FitterCardBack fitter={fitter} /></div>;

  const toggleModal = (e) => {
    e.stopPropagation()
    setIsModalVisible(!isModalVisible)
  }

  const checkIfActiveFitterAndChangeStyles = (fitter[matchParameter] === activeFitterIdentifier) ? {"borderLeft": '5px solid #9D1E4B'} : {    "borderLeft": "5px solid #11244C"
  };

  const modalBodyStyles = {
    borderLeft: "5px solid #9D1E4B",
    padding: "0px",
  }

  return (
    <section className={`${styles.FitterCard}`} onClick={()=> functionOnSelectFitter(fitter[matchParameter])} style={checkIfActiveFitterAndChangeStyles}>
      {fitterCardFrontJSX}
      <Button type="button" className={`${styles.fitterCardButton} rebrand-primary`} onClick={(e) => toggleModal(e)}>More</Button>
      <Modal title={fitter.fitterName} visible={isModalVisible} onCancel={() => setIsModalVisible(false)} footer={null} bodyStyle={modalBodyStyles}>
        {fitterCardBackJSX}
      </Modal>
    </section>
  );
};

export default FitterCard;