import React from "react";
import styles from "./Breadcrumb.module.scss";
import { RightOutlined } from '@ant-design/icons'

import { useState, useEffect } from 'react';

const Breadcrumb = ({journeyStage}) => {
    const [partnerStyle, updatePartnerStyle] = useState("");
    const [fitterStyle, updateFitterStyle] = useState("");
    const [paymentsStyle, updatePaymentsStyle] = useState("");
    const [agreementStyle, updateAgreementStyle] = useState("");

    const getStyles = (journeyStage) => {
        switch(journeyStage) {
            case"terms-and-conditions":
                updatePartnerStyle(styles.activeStep)
                break;
            case "create-fitting-centres": 
                updatePartnerStyle(styles.completeStep)
                updateFitterStyle(styles.activeStep)
                break;
            case "stripe-signup":
                updatePartnerStyle(styles.completeStep)
                updateFitterStyle(styles.completeStep)
                updatePaymentsStyle(styles.activeStep)
                break;
            case "partner-agreement":
                updatePartnerStyle(styles.completeStep)
                updateFitterStyle(styles.completeStep)
                updatePaymentsStyle(styles.completeStep)
                updateAgreementStyle(styles.activeStep)
                break;
            default:
              break;
        }
    }

    useEffect(() => {
        getStyles(journeyStage)
    }, [journeyStage])

  return (
    <div className={styles.breadcrumb}>
        <p className={partnerStyle}>Partner</p>
        <p><RightOutlined /></p>
        <p className={fitterStyle}>Fitters</p>
        <p><RightOutlined /></p>
        <p className={paymentsStyle}>Payments</p>
        <p><RightOutlined /></p>
        <p className={agreementStyle}>Contract</p>
    </div>
  );
};

export default Breadcrumb;