import React from 'react';
//$FlowFixMe
import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
// import setLocation from '../../methods/setLocation';
import styles from './PartnerAgreementScene.module.scss';
import PartnerService from '../../services/partner';
import NavLocation from '../../components/navLocation';
import Button from '@motokiki/shared/button';
import LoadingModal from '@motokiki/shared/loadingModal';
import { message } from 'antd';
import congratulations from '../../assets/Congrats.png';
import EmailService from '../../services/email';

type Props = {
  history: {
    push: (url: string) => void,
    location: Object
  }
};

type State = {
  auth0Profile: Object,
  partner: Object,
  isLoading: Boolean
};

class PartnerAgreementScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auth0Profile: {},
      partner: {},
      isLoading: true
    };
  }

  async componentDidMount() {
    await this.setUpPage();
  }

  setUpPage = async () => {
    // const { history } = this.props;
    const auth0Profile = await auth0Client.getProfile();
    const auth0ID = auth0Profile && auth0Profile.sub;
    const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
    // setLocation(history.location.pathname, showHome[1], history);
    this.setState({
      partner: showHome[0]
    });
    try {
      const partnerWithForm = await this.createOrderPDF(this.state.partner._id);
      const confirmationEmail = await this.sendConfirmationEmail(this.state.partner._id);

      if (partnerWithForm) {
        message.success('Success creating contract');
        this.setState({ partner: partnerWithForm });
        this.setState({
          isLoading: false
        });
      } else {
        message.error('Error creating contract');
        this.setState({
          isLoading: false
        });
      }

      if (confirmationEmail) {
        message.success('Success sending confirmation');
      } else {
        message.error('Error sending confirmation');
      }
      
    } catch (err) {
      console.error(err);
      message.error('Error creating contract');
      this.setState({
        isLoading: false
      });
    }
  };

  sendConfirmationEmail = async partnerId => {
    const { REACT_APP_SEND_EMAILS } = process.env;
    let emailsSent = false;

    if (REACT_APP_SEND_EMAILS === 'true') {
      emailsSent = await EmailService.sendInitialSignUpEmails(partnerId)
    }
    return emailsSent;
  }

  createOrderPDF = async partnerId => {
    const updatedPartner = await PartnerService.createPartnerOrderForm(
      partnerId
    );
    return updatedPartner;
  };

  completeSignUpProcess = () => {
    const { history } = this.props;
    history.push('/');
  };

  render() {
    const { partner, isLoading } = this.state;
    return (
      <div>
        {NavLocation('Sign up complete', 'partner-agreement')}
        {!isLoading ? (
          <div className='container'>
            <div className={styles.contentBox}>
              <div className={styles.congratsImg}>
                <img
                  className={styles.congratsImgSize}
                  src={congratulations}
                  alt='congratulations fireworks'
                />
              </div>
              <p>
                You've completed your part of the sign up process for Motokiki!
                You can update your business and fitter information from your
                dashboard
              </p>
              <p>
                Stapleton's need to check your information before we can
                activate you on the main Motokiki platform, we aim to have this
                completed within 5 working days.
              </p>
              <p>
                Download a copy of your contract, agreement and the general
                terms and conditions below below (you can also access this from
                the business information page on your dashboard).
              </p>
              <div className={styles.allButtons}>
                <div>
                  <div className={styles.buttonContainer}>
                    <div className={styles.button}>
                      <Button className={'rebrand-primary'}>
                        <a
                          href={partner.documents.orderForm.fileUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={styles.link}
                        >
                          {' '}
                          Your Motokiki Agreement
                        </a>
                      </Button>
                    </div>
                    <div className={styles.button}>
                      <Button className={'rebrand-primary'}>
                        <a
                          href={partner.documents.retailerContract.fileUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={styles.link}
                        >
                          {' '}
                          Your Motokiki Contract
                        </a>
                      </Button>
                    </div>
                    <div className={styles.button}>
                      <Button className={'rebrand-primary'}>
                        <a
                          href={partner.documents.retailerMotokikiTerms.fileUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          className={styles.link}
                        >
                          General Motokiki T&Cs
                        </a>
                      </Button>
                    </div>
                  </div>
                  <div className={styles.finishButton}>
                    <Button
                      className={'rebrand-primary'}
                      onClick={() => this.completeSignUpProcess(partner._id)}
                    >
                      Return to dashboard
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <LoadingModal isOpen={isLoading} loadingText='Creating contract...' />
        )}
      </div>
    );
  }
}

export default PartnerAgreementScene;
