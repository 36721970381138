/* @flow */
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import React from 'react';

import LoadingModal from '@motokiki/shared/loadingModal';

import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import createNewStripe from '../../services/createNewStripe';
import setLocation from '../../methods/setLocation';
import EmailService from '../../services/email';

type Props = {
  history: {
    location: {
      hash: string,
      pathname: string,
      search: string
    },
    replace: (url: string) => void
  }
};

const { REACT_APP_SEND_EMAILS } = process.env;

class Callback extends React.Component<Props> {
  async componentDidMount() {
    const { history } = this.props;
    //authcheck
    if (history && history.location && history.location.hash) {
      await auth0Client.handleAuthentication();
      const auth0profile = await auth0Client.getProfile();
      //not logged in or auth error
      if (auth0profile.error) history.replace('/');
      const auth0ID = auth0profile && auth0profile.sub;
      const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
      if (showHome.error) history.replace('/');
      setLocation(history.location.pathname, showHome[1], history);
    } else {
      //if stripe is connected redirect to the dashboard
      const params = queryString.parse(history.location.search);
      if (params.code) {
        const auth0profile = await auth0Client.getProfile();
        const auth0ID = auth0profile && auth0profile.sub;
        const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
        const partner = showHome[0];
        //environment variables always strings, so a pseudo 'boolean' below
        if (REACT_APP_SEND_EMAILS === 'true') {
          const emailsSent = await EmailService.sendInitialSignUpEmails(
            partner._id
          );
          console.info('emails res: ', emailsSent);
        }
        await createNewStripe.createNewStripe(params.code).then(() => {
          history.replace('/');
        });
        //if stripe not connected redirect to stripe signup
      } else {
        const auth0profile = await auth0Client.getProfile();
        const auth0ID = auth0profile && auth0profile.sub;
        await checkSignUp(auth0ID);
        history.replace('/stripe-signup');
      }
    }
  }

  render() {
    return (
      <div>
        <LoadingModal loadingText='Loading your Profile' isOpen />
      </div>
    );
  }
}

export default withRouter(Callback);
