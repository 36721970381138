import React, { useState, useEffect, Fragment } from 'react';

import { Col, Checkbox, message, Row, Button } from 'antd';
import { useForm, Controller, useFormState } from 'react-hook-form';

import LoadingModal from '@motokiki/shared/components/LoadingModal';

import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import partnerService from '../../services/partner';
import NavLocation from '../../components/navLocation';
import setLocation from '../../methods/setLocation';
import StripeButton from '../../components/OfficalStripeButton';

type Props = {
  history: {
    push: (url: string) => void,
    location: Object
  }
};

const StripeSignUp = (props: Props) => {
  const [userData, setUserData] = useState({});
  const { isSubmiting } = useFormState;
  const [auth0ID, setAuth0ID] = useState();
  const { history } = props;

  /*-----------------------Submit Handler---------------------*/
  const submitHandler = async data => {
    try {
      const haveWeUpdatedPartner = await partnerService.update({
        auth0ID: auth0ID,
        ...userData,
        ...data
      });
      if (haveWeUpdatedPartner) {
        message.success('Payment method saved.');
        if (watchShowStripe) {
          const {
            REACT_APP_REDIRECT_URL,
            REACT_APP_STRIPE_CLIENT_ID
          } = process.env;
          let url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${REACT_APP_STRIPE_CLIENT_ID}&scope=read_write`;

          url = `${url}&stripe_user[street_address]=${userData.address1}&stripe_user[city]=${userData.city}&stripe_user[zip]=${userData.postcode}&stripe_user[country]=GB&stripe_user[email]=${userData.contactEmail}&stripe_user[business_name]=${userData.name}&stripe_user[business_type]=company&redirect_uri=${REACT_APP_REDIRECT_URL}`;
          window.location.href = url;
        } else {
          history.push('/partner-agreement');
        }
      } else {
        message.error('Error saving payment method');
      }
    } catch (err) {
      console.error(err.response);
      message.error(`Error saving payment method: ${err.response.data.error}`);
    }
  };

  /*--------------------------Auth----------------------*/
 
  useEffect(() => {
    const initAuth = async () => {
      const auth0profile = await auth0Client.getProfile();
      const auth0ID = auth0profile && auth0profile.sub;
      const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
      setUserData(showHome[0]);
      setLocation(history.location.pathname, showHome[1], history);
      setAuth0ID(auth0ID);
    };
    initAuth();
  }, [history]);
  /*--------------------------Form----------------------*/
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      offerPayInStore: true,
      offerPayOnline: false
    }
  });

  const style = { padding: '8px 0' };
  const watchShowStripe = watch('offerPayOnline', false);

  return (
    <div>
      {NavLocation('Payments', 'stripe-signup')}
      {userData ? (
        <div
          style={{
            backgroundColor: 'white',
            padding: '30px 40px',
            borderRadius: '5px',
            maxWidth: '1071px',
            margin: 'auto'
          }}
        >
          <h2>Select at least one payment method</h2>
          <form onSubmit={handleSubmit(submitHandler)} id='payment-method'>
            <Row gutter={16}>
              <Col className='gutter-row' span={24}>
                <Controller
                  name='offerPayInStore'
                  control={control}
                  render={({ field }) => {
                    return (
                      <Checkbox
                        style={style}
                        {...field}
                        className='checkbox'
                        checked={field.value}
                        onChange={({ target }) => {
                          setValue('offerPayOnline', true);
                          return field.onChange(target.checked);
                        }}
                      >
                        Pay in Store
                      </Checkbox>
                    );
                  }}
                />
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className='gutter-row' span={24}>
                <Controller
                  name='offerPayOnline'
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      style={style}
                      className='checkbox'
                      {...field}
                      checked={field.value}
                      onChange={({ target }) => {
                        setValue('offerPayInStore', true);
                        return field.onChange(target.checked);
                      }}
                    >
                      Pay Online
                    </Checkbox>
                  )}
                />
              </Col>
            </Row>
            {watchShowStripe ? (
              <Fragment>
                <Row>
                  <h3>Stripe registration</h3>
                  <p>
                    Our Stripe integration allows you to accept payments from
                    almost any card and payment method including{' '}
                    <b>Apple Pay</b> {'&'} <b>Google Pay</b>.
                  </p>

                  <p>
                    Funds will be paid directly into you bank account 7 days
                    after the transaction has happened
                  </p>
                  <p>
                    By registering your account, you agree to our Services
                    Agreement and the Stripe Connected Account Agreement. We've
                    provided a{' '}
                    <a
                      href='https://partnerkiki-static-assets.s3.eu-west-2.amazonaws.com/stripe-pdfs/connecting_stripe_account_infographic.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      handy guide
                    </a>{' '}
                    if you are unsure how to connect your account!
                  </p>
                </Row>
                <Row>
                  <StripeButton form='payment-method' loading={isSubmiting} />
                </Row>
              </Fragment>
            ) : (
              <Button
                type='submit'
                htmlType='submit'
                form='payment-method'
                size='large'
                shape='round'
                className='rebrand-primary'
                loading={isSubmiting}
              >
                Continue →
              </Button>
            )}
          </form>
        </div>
      ) : (
        <LoadingModal loadingText='Getting data... ' />
      )}
    </div>
  );
};

export default StripeSignUp;
