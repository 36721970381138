/* @flow */
import React from 'react';
import { Box, Flex } from '@rebass/grid';

// $FlowFixMe
import './Footer.scss';

// const { REACT_APP_STATIC_HOST } = process.env || {
//   REACT_APP_STATIC_HOST: 'http://www.motokiki.com'
// };

type Props = {
  type?: 'links' | 'simple'
};

type State = {
  width: number
};

export default class Footer extends React.Component<Props, State> {
  state = {
    width: window.innerWidth
  };

  static defaultProps = {
    type: 'simple'
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const { type } = this.props;
    const { width } = this.state;

    const media = {};
    media.isMobile = width <= 600;
    media.isTablet = width > 599 && width <= 1024;
    media.isDesktop = width > 1023;

    return (
      <footer className="mk-footer">
        {type === 'simple' && (
          <Flex
            className="mk-footer-simple__container"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width={5 / 6}
              className="mk-footer-simple-logos__container"
              mt={3}
            >
              <Flex alignItems="flex-end">
                <Box className="mk-footer-simple-logos__logo" mr={3}>
                  <a href="/">
                    <img
                      src="https://motorkiki-rebrand-static-assets.s3.eu-west-2.amazonaws.com/images/logos/MK_logo.svg"
                      alt="Motokiki Logo"
                    />
                  </a>
                </Box>
                <Box className="mk-footer-simple-logos__logo">
                  <p className="fs-footer-xs">In partnership with</p>
                  <a href="https://www.tyresafe.org/">
                    <img
                      alt="tyre safe logo"
                      src="https://partnerkiki-static-assets.s3.eu-west-2.amazonaws.com/assets/tyresafe.png"
                    />
                  </a>
                </Box>
              </Flex>
            </Box>
            <Box
              width={5 / 6}
              className="mk-footer-simple-links__container"
              mt={4}
            >
              {/* <Flex flexWrap="wrap">
                <Box mr={3}>
                  <a
                    className="mk-footer-simple-links__link fs-body"
                    // href={`${REACT_APP_STATIC_HOST || ''}/contact-us`}
                    href="mailto:contact@motokiki.com"
                  >
                    Contact Us
                  </a>
                </Box>
                <Box mr={3}>
                  <a
                    className="mk-footer-simple-links__link fs-body"
                    // href={`${REACT_APP_STATIC_HOST || ''}/cookies`}
                    href="https://www.motokiki.com/cookies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Cookie Policy
                  </a>
                </Box>
                <Box mr={3}>
                  <a
                    className="mk-footer-simple-links__link fs-body"
                    // href={`${REACT_APP_STATIC_HOST || ''}/privacy`}
                    href="https://www.motokiki.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </Box>
                <Box mr={3}>
                  <a
                    className="mk-footer-simple-links__link fs-body"
                    href={GeneralTermsAndConditionsPDF} target='_blank' rel='noreferrer noopener'
                  >
                    Terms and Conditions
                  </a>
                </Box>
              </Flex> */}
            </Box>
          </Flex>
        )}
      </footer>
    );
  }
}
