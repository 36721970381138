
import React from "react";
import styles from "./FitterSidebar.module.scss";
import FitterCard from "../FitterCard/FitterCard";

const FitterSidebar = ({partner, fitterList, matchParameter, activeFitterIdentifier, functionOnSelectFitter}) => {

  return (
    <section className={styles.FitterSidebar}>
      <h2 className={styles.title}>Your fitting centres:</h2>
        <div className={styles.fitterCardArray}>
          {fitterList.map(fitter => {
            return (
              <div className={styles.fitterCard}>
                  <FitterCard
                  image={partner.logo}
                  key={fitter._id}
                  fitter={fitter}
                  matchParameter={matchParameter}
                  activeFitterIdentifier={activeFitterIdentifier}
                  functionOnSelectFitter={functionOnSelectFitter}
                  />
              </div>)
            })}
        </div>
    </section>
  );
};

export default FitterSidebar;