/* @flow */
import { Button, Form, message, Row, Switch, Upload } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserDeleteOutlined
} from '@ant-design/icons';

import Input from '@motokiki/shared/input';
import Label from '@motokiki/shared/label';
import LoadingModal from '@motokiki/shared/components/LoadingModal/LoadingModal';
import Wysiwyg from '@motokiki/shared/wysiwyg'
import Tooltip from '@motokiki/shared/tooltip';
import InfoCircle from '@ant-design/icons/InfoCircleOutlined';
import React from 'react';
import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import partnerService from '../../services/partner';
import networkService from '../../services/network';
import verifyFields from '../../methods/verifyFields';
import compileTermsAndConditions from '../../methods/compileTermsAndConditions';
// $FlowFixMe
import styles from './CreatePartnerScene.module.scss';
import NavLocation from '../../components/navLocation';

type Props = {

  history: {
    push: (url: string) => void,
    location: Object
  }
};

type State = {
  auth0profile: Object,
  stapletonsData: Object,
  logoFile: any,
  preview: boolean,
  prvImg: string | ArrayBuffer,
  accInfo: Boolean,
  wysiwygKey: String,
  isLoading: Boolean,

  partner: {
    name: string,
    contactEmail: string,
    phoneNumber: string,
    logo: any,
    _id: string,
    stapletonsID: string,
    networkId: string,
    companyRegistrationNumber: string,
    companyVatNumber: string,
    contactName: string,
    termsAndConditionsUrl: string,
    termsAndConditionsText: string,
    address1: string,
    address2: string,
    address3: string,
    city: string;
    county: string;
    postcode: string;
    offerDelivery: Boolean,
    offerMobile: Boolean,
    offerFitting: Boolean,
    accountsContact? : {
      accountsPhone?: String,
      accountsEmail?: String,
      accountsName?: String
    },
  },
};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class CreatePartnerScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auth0profile: {},
      step: 0,
      logoFile: null,
      preview: false,
      prvImg: new ArrayBuffer(1),
      wysiwygKey: "",
      accInfo: false,
      partner: {
        offerFitting: true,
        offerDelivery: false,
        offerMobile: false,
        accountsContact: {
          accountsName: "",
          accountsEmail: "",
          accountsPhone: ""
        }
      },
      isLoading: true
    };
  }

  componentDidMount() {
    this.setUpForm();
  }

  getNetworkId = async (networkName: string) => {
    const haveWeGotNetwork = await networkService.getNetwork(networkName)
    if (haveWeGotNetwork) {
      return haveWeGotNetwork;
    } else {
      message.error("Can't retrieve Network information")
    };
  };

  handleSubmit = async (e: Event) => {
    e.preventDefault();
    const { history } = this.props;
    const { partner, auth0profile, logoFile } = this.state;

    if (verifyFields({}, partner, message, 'partner') === true) {
      if (logoFile !== null) {
        partner.logo = logoFile;
      }
      this.setModal(true)

      //need to check if partner exists in db first - if there is an error in later update func, it may still have created a 'blank' partner associated with the auth0 profile
      if (partner._id) {
        try {
            const haveWeUpdatedPartner = await partnerService.update({
            auth0ID: auth0profile.sub, ...partner
            })
            if (haveWeUpdatedPartner) {
              message.success("Success adding partner")
              history.push('/create-fitting-centres')
            } else {
              message.error("Error updating partner")
              this.setModal(false)
            }
        } catch(err) {
            console.error(err.response)
            message.error(`Error creating partner: ${err.response.data.error}`)
            this.setModal(false)
        }
      } else {
          //if no partner for this profile yet, try to create one then update it with major info
          try {
            const haveWeGotNetwork = await this.getNetworkId("Stapletons");
            partner.networkId = haveWeGotNetwork.data._id;

            if (haveWeGotNetwork) {
              try {
                const haveWeCreatedPartner = await partnerService.create(
                  partner.stapletonsID
                );

                if (haveWeCreatedPartner) {
                  try {
                    partner._id = haveWeCreatedPartner.data._id;
                    partner.jobRunner = haveWeCreatedPartner.data.jobRunner
                    const haveWeUpdatedPartner = await partnerService.update({ auth0ID: auth0profile.sub, ...partner})
          
                    if (haveWeUpdatedPartner) {
                      message.success("Success adding Partner");
                      history.push('/create-fitting-centres')
                    } else {
                      message.error("Error updating partner")
                      this.setModal(false)
                    }

                  } catch(err) {
                    console.error(err.response)
                    message.error(`Error creating partner: ${err.response.data.error}`)
                    this.setModal(false)
                  }
                }
              } catch (err) {
                console.error(err.response)
                message.error(`Error creating partner: ${err.response.data.error}`)
                this.setModal(false)
              }  
            }
          } catch (err) {
            console.error("error: ",err.response)
            message.error("Error finding Stapletons Network")
            this.setModal(false)
          }
      }
    } else {
      message.error("please check your input data")
    }
  };

  setModal = (modalState) => {
    this.setState({
      isLoading: modalState
    })
  }

  setUpForm = async () => {
    // const { history } = this.props;
    window.scrollTo(0, 0)

    // const { partner } = this.state;
    const auth0profile = await auth0Client.getProfile();
    const auth0ID = auth0profile && auth0profile.sub;
    const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
    // checks to see if we are in the correct location
    // setLocation(history.location.pathname, showHome[1], history);
    // set fields values to stapletons values
    // partner.name = (showHome[0] && showHome[0].name) || '';

    // set default open and closing times
    this.setState({
      stapletonsData: showHome[0],
      auth0profile,
      showHome: showHome[1],
    });
    this.setModal(false)
  };

  close = () => {
      this.setState({ preview: false })
    };

  handleInput = (event, ob, name, anotherLayer) => {
    const value = event.target.value;
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name]
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: { ...val, [`${name}`]: {...valDeeper, [`${anotherLayer}`]: value} }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  handleToggle = (value, ob, name, anotherLayer) => {
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name]
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: { ...val, [`${name}`]: {...valDeeper, [`${anotherLayer}`]: value} }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  handleWysiwyg = (event, ob, name, anotherLayer) => {
    //JPN: wyswig component doesn't return the event, it returns the event.target.value
    const value = event;
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name]
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: { ...val, [`${name}`]: {...valDeeper, [`${anotherLayer}`]: value} }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  prePopulateTermsAndConditionsText = async (partner) => {
    const finalTAndCs = compileTermsAndConditions(partner);
     this.setState({ 
      partner: {...this.state.partner, termsAndConditionsText: finalTAndCs},
      wysiwygKey: "newTAndCs",
    })
    const updateTime = new Date()
    //JPN Toggling the state for the wysiwyg key forces rerender of wysiwyg with compiled T+Cs
     this.setState({
       wysiwygKey: `updated at ${updateTime}`
     })
  }

  generateAccInfoJSX = (accInfo) => {
    const { partner } = this.state; 
    const accInfoJSX = accInfo ? (<div>
            <Row style={{ paddingTop: '20px' }}>
              <Label>
                Accounts Email (If different)
                <Input
                  size="large"
                  placeholder="email@email.com"
                  value={partner.accountsContact.accountsEmail}
                  onChange={e => this.handleInput(e, 'partner', 'accountsContact', 'accountsEmail')}
                />
              </Label>
            </Row>
            <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
              <Label>
                Accounts Phone number (If different)
                <Input
                  size="large"
                  placeholder="01189000000"
                  value={partner.accountsContact.accountsPhone}
                  onChange={e => this.handleInput(e, 'partner', 'accountsContact', 'accountsPhone')}
                />
              </Label>
            </Row>
            <Row style={{ paddingTop: '20px' }}>
              <Label>
                Accounts Name of contact (If applicable)
                <Input
                  size="large"
                  placeholder="Name of accounts contact"
                  value={partner.accountsContact.accountsName}
                  onChange={e => this.handleInput(e, 'partner', 'accountsContact', 'accountsName')}
                />
              </Label>
            </Row>
            <Button
          shape='round'
          type='secondary'
          onClick={() => {
            this.toggleAccInfo(!this.state.accInfo);
          }}
        >
          <UserDeleteOutlined /> Remove contact
        </Button>
    </div>)
    : null;
    return accInfoJSX;
  }

  toggleAccInfo = (newState) => {
    this.setState({
      accInfo: newState
    });
  };

  onLogoChange = info => {
    const file = info.file;
    const isSupported =
      ['image/png', 'image/jpg', 'image/jpeg'].indexOf(file.type) !== -1;

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isSupported) {
      message.error(`${file.name} is not a jpg or png file`);
    }
    if (!isLt2M) {
      message.error(`${file.name} is bigger than 2Mb`);
    }
    if (isLt2M && isSupported) {
      getBase64(info.file, imageUrl =>
        this.setState({ logoFile: imageUrl })
      );
    }
  };

  render() {
    const { stapletonsData, partner, isLoading } = this.state;

    return (
      <div>
        {NavLocation("Partner Registration", this.state.showHome)}
        <div className={styles.container}>
          {stapletonsData ? (
            <Form onSubmit={this.handleSubmit} className={styles.form}>
              <div>
                <h2>Contact information</h2>
                <Label>
                  <div className={styles.tooltipLabelBox}>
                    <p className={styles.tooltipLabel}>Partner Name*</p>
                    <Tooltip placement="right" targetId="partnerName" tooltipText="Your business name (mandatory)">
                      <InfoCircle />
                    </Tooltip>
                  </div>
                  <Input
                    size="large"
                    placeholder="Core business name"
                    value={partner.name}
                    onChange={e => this.handleInput(e, 'partner', 'name')}
                  />
                </Label>
                <Row style={{ paddingTop: '20px' }}>
                  <Label>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Business email address*</p>
                      <Tooltip placement="bottom" targetId="businessEmail" tooltipText="Your business email (standard email format, mandatory)">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="Core business email"
                      value={partner.contactEmail}
                      onChange={e => this.handleInput(e, 'partner', 'contactEmail')}
                    />
                  </Label>
                </Row>
                <Row style={{ paddingTop: '20px' }}>
                  <Label>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Business phone number*</p>
                      <Tooltip placement="bottom" targetId="businessPhone" tooltipText="Your business phone number (mandatory)">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="01189000000"
                      value={partner.phoneNumber}
                      onChange={e => this.handleInput(e, 'partner', 'phoneNumber')}
                    />
                  </Label>
                </Row>
                <Label className={styles.labelTitle}>Business address</Label>
                  <Row style={{ paddingTop: '20px' }}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Address line 1*</p>
                      <Tooltip placement="bottom" targetId="address1" tooltipText="Your business address (mandatory)">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="123 Your Road"
                      value={partner.address1}
                      onChange={e => this.handleInput(e, 'partner', 'address1')}
                    />
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Address line 2</p>
                      <Tooltip placement="bottom" targetId="address2" tooltipText="Your business address">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="Your area"
                      value={partner.address2}
                      onChange={e => this.handleInput(e, 'partner', 'address2')}
                    />
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Address line 3</p>
                      <Tooltip placement="bottom" targetId="address3" tooltipText="Your business address">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder=""
                      value={partner.address3}
                      onChange={e => this.handleInput(e, 'partner', 'address3')}
                    />
                  </Row>
                  <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>City*</p>
                      <Tooltip placement="bottom" targetId="addressCity" tooltipText="Your business address (mandatory)">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="Your City"
                      value={partner.city}
                      onChange={e => this.handleInput(e, 'partner', 'city')}
                    />
                  </Row>
                  <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>County*</p>
                      <Tooltip placement="bottom" targetId="addressCounty" tooltipText="Your business address (mandatory)">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="Your county"
                      value={partner.county}
                      onChange={e => this.handleInput(e, 'partner', 'county')}
                    />
                  </Row>
                  <Row style={{ padding: '20px 0' }} className={styles.smaller}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Postcode*</p>
                      <Tooltip placement="bottom" targetId="postcode" tooltipText="Your business postcode (mandatory, standard UK format)">
                        <InfoCircle />
                      </Tooltip>
                    </div>
                    <Input
                      size="large"
                      placeholder="AB12CD"
                      value={partner.postcode}
                      onChange={e => this.handleInput(e, 'partner', 'postcode')}
                    />
                  </Row>
                  <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
                    <Label>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Company registration number</p>
                        <Tooltip placement="bottom" targetId="companyReg" tooltipText="Your Company Registration No.">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size="large"
                        placeholder="Company reg number"
                        value={partner.companyRegistrationNumber}
                        onChange={e => this.handleInput(e, 'partner', 'companyRegistrationNumber')}
                      />
                    </Label>
                  </Row>
                  <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
                    <Label>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Company VAT number*</p>
                        <Tooltip placement="bottom" targetId="vatNo" tooltipText="Your company VAT no. (mandatory)">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size="large"
                        placeholder="Company VAT number"
                        value={partner.companyVatNumber}
                        onChange={e => this.handleInput(e, 'partner', 'companyVatNumber')}
                      />
                    </Label>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Label>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Select Tyre fitting/delivery options</p>
                        <Tooltip placement="bottom" tooltipText="Select your fitting offerings">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <div className={styles.fitterOptionsCont}>
                        <div className={styles.fitterOption}>
                          <p>On site Fitting</p>
                          <Switch
                            checked={partner.offerFitting}
                            onChange={checked => {
                              this.handleToggle(
                                checked,
                                'partner',
                                'offerFitting'
                              );
                            }}
                          />
                        </div>
                        {/* <div className={styles.fitterOption}>
                          <p>Delivery only</p>
                          <Toogle
                          isChecked={partner.offerDelivery}
                            onUpdate={e => this.handleToggle(e, 'partner', 'offerDelivery' )}
                          />
                        </div>
                        <div className={styles.fitterOption}>
                          <p>Mobile Fitting</p>
                          <Toogle
                          isChecked={partner.offerMobile}
                            onUpdate={e => this.handleToggle(e, 'partner', 'offerMobile'  )}
                          />
                        </div> */}
                      </div>
                    </Label>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Label>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Name of contact*</p>
                        <Tooltip placement="bottom" targetId="contactName" tooltipText="Name of a contact between your business and Motokiki (mandatory)">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size="large"
                        placeholder="Name"
                        value={partner.contactName}
                        onChange={e => this.handleInput(e, 'partner', 'contactName')}
                      />
                    </Label>
                  </Row>
                  <h3>Accounts contact info (if different)</h3>
                    {!this.state.accInfo && (
                      <Button
                        shape='round'
                        type='secondary'
                        onClick={() => {
                          this.toggleAccInfo(!this.state.accInfo);
                        }}
                      >
                        <UserAddOutlined /> Add contact
                      </Button>
                    )}
                  <Row style={{ paddingTop: '20px' }}>
                    {this.generateAccInfoJSX(this.state.accInfo)}
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <h2>Your company logo</h2>
                    <Label>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Please upload a copy of your company’s logo (optional).</p>
                        <Tooltip placement="bottom" targetId="logoImage" tooltipText="Your logo image, will be cropped to a square shape. Accepted file types are GIF, JPEG, PNG or SVG.">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                    </Label>
                    <Upload
                        beforeUpload={() => false}
                        className='svg_overlay_container'
                        accept='.jpeg,.jpg,.png'
                        name='logoImageFile'
                        showUploadList={false}
                        onChange={this.onLogoChange}
                      >
                        {this.state.logoFile ? (
                          <>
                            <div className='svg_overlay'>
                              <EditOutlined className='svg_overlay__icon'/>
                              <DeleteOutlined 
                                onClick={(e)=> {
                                  e.stopPropagation();
                                  this.setState({logoFile: null})
                                }}
                                className='svg_overlay__icon'
                              />
                            </div>
                            <img
                              src={this.state.logoFile}
                              alt='avatar'
                              style={{ maxWidth: '200px', maxHeight: '200px' }}
                            />
                          </>
                        ) : (
                          <Button shape='round' type='secondary'>
                            <UploadOutlined />
                            Click to Upload
                          </Button>
                        )}
                      </Upload>
                  </Row>
                  <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
                    <Label className={styles.labelTitle}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Stapletons account number*</p>
                        <Tooltip placement="bottom" targetId="stapletonsNo" tooltipText="Your business' Stapletons Account no. (mandatory)">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size="large"
                        placeholder="Stapleton's Acc. No."
                        value={partner.stapletonsID}
                        onChange={e => this.handleInput(e, 'partner', 'stapletonsID')}
                      />
                    </Label>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Label className={styles.labelTitle}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Terms and conditions URL (if you have them hosted externally)</p>
                        <Tooltip placement="bottom" targetId="tAndCUrl" tooltipText="If you have your terms and conditions hosted externally, you can input the URL here">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size="large"
                        placeholder="https://mytermsandconditionslocation.com"
                        value={partner.termsAndConditionsUrl}
                        onChange={e=> this.handleInput(e, 'partner', 'termsAndConditionsUrl')}
                      />
                    </Label>
                  </Row>
                  <Row style={{ paddingTop: '20px' }}>
                    <Label className={styles.labelTitle}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Use our standard template for terms and conditions with the information you've already provided:</p>
                        <Tooltip placement="bottom" targetId="tAndCsButton" tooltipText="Make sure the above info is correct, then you can use the button to autogenerate a T&Cs template with your info">
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Row style={{ paddingTop: '20px', textAlign: 'center' }}>
                        <Button type='mk'
                          size='large'
                          shape='round'
                          className='rebrand-primary stripe-btn'
                          onClick={() => this.prePopulateTermsAndConditionsText(partner)}
                        >
                          Use standard T&Cs
                        </Button>
                      </Row>
                    </Label>
                  </Row>
                  <Row style={{ paddingTop: '20px', paddingBottom: '20px'}}>
                    <Label className={styles.labelTitle}>
                    <div className={styles.tooltipLabelBox}>
                      <p className={styles.tooltipLabel}>Paste or enter your terms and conditions*</p>
                      <Tooltip placement="bottom" targetId="tAndCs" tooltipText="Your business terms and conditions (mandatory)">
                        <InfoCircle />
                      </Tooltip>
                      </div>
                      <Wysiwyg 
                        className={styles.wysiwyg}
                        placeholder="Your terms and conditions here"
                        value={partner.termsAndConditionsText}
                        key={this.state.wysiwygKey}
                        onChange={e => this.handleWysiwyg(e, 'partner', 'termsAndConditionsText')}
                        />
                    </Label>
                  </Row>
              </div>
                <div className={styles.stepsAction}>
                  <Button 
                    className={'rebrand-primary'}
                    shape='round'
                    size='large'
                    type='mk'
                    loading={isLoading}
                    loadingText="Uploading"
                    onClick={e => this.handleSubmit(e)}>Continue →</Button>
                </div>
            </Form>
          ) : (
            <LoadingModal isOpen={isLoading} loadingText="Getting data.." />
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(CreatePartnerScene);