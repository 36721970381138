const verifyFields = (fitter, partner, message, stepName) => {

  let validationResult = true;

  const validateEmail = (email) => {
    if (!email) {
      message.error('Please enter a valid email address')
      return false
    }
    if (
      (/@/gi).test(email) &&
      (/\./gi).test(email) && 
      (/\s/gi).test(email) === false &&
      (/<script>/gi).test(email) === false
      ) {
      return true;
    } else {
      message.error('Please enter a valid email address.');
      return false;
    }
  }

  const validateText = (text) => {
    if (!text) {
      message.error('Please complete all mandatory fields')
      return false
    }
    if (
      (/<script>/gi).test(text) === false
    ) {
      return true;
    } else {
      message.error('Please return a value without HTML tag');
      return false;
    }
  }

  const validatePostcode = (postcode) => {
    if (!postcode) {
      message.error("Please return a valid UK postcode")
      return false
    }
    if (
      (/^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i).test(postcode) &&
      (/<script>/gi).test(postcode) === false
    ) {
      return true;
    } else {
      message.error("Please return a valid UK postcode");
      return false;
    }
  }

  const validateNumber = (number) => {
    if (!number) {
      return false
    }
    if(
      (/^[0-9]*$/gi).test(number)
    ) {
      return true;
    } else {
      message.error("Please only insert numbers in wheel size and days notice fields");
      return false;
    }
  }

  const validateWheelSizes = (wheelSizeObject) => {
    const numberMin = parseInt(wheelSizeObject.min, 10);
    const numberMax = parseInt(wheelSizeObject.max, 10);
    if (numberMax > numberMin) {
      return true;
    } else {
      message.error(`Your maximum wheel size must be greater than your minimum wheel size, min size must be at least 10"`);
      return false;
    }
  }

  const validatePhone = (phoneNumber) => {
    if (!phoneNumber) {
      return false;
    }

    if (
      (/^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?#(\d{4}|\d{3}))?$/gi).test(phoneNumber)
    ) {
      return true;
    } else {
      message.error("Please insert a valid UK-format phone number")
      return false;
    }
  }
  
  const errorChecker = (errorArray) => {
    return errorArray.every(item => item === true)
  }

  const validatePartnerInfo = (partner) => {
    const {
      name,
      contactEmail,
      phoneNumber,
      stapletonsID,
      companyRegistrationNumber,
      contactName,
      address1,
      address2,
      address3,
      city,
      county,
      postcode,
      companyVatNumber,
      accountsContact,
      termsAndConditionsUrl,
      termsAndConditionsText
    } = partner

    const errorArray = [];

    errorArray.push(validateText(name))
    errorArray.push(validateEmail(contactEmail))
    errorArray.push(validatePhone(phoneNumber))
    errorArray.push(validateText(stapletonsID))
    if (companyRegistrationNumber) {
      errorArray.push(validateText(companyRegistrationNumber))
    }
    errorArray.push(validateText(companyVatNumber))
    errorArray.push(validateText(contactName))
    errorArray.push(validateText(address1))
    if(address2) {
      errorArray.push(validateText(address2))
    }
    if (address3) {
      errorArray.push(validateText(address3))
    }
    errorArray.push(validateText(city))
    errorArray.push(validateText(county))
    errorArray.push(validatePostcode(postcode))
    if (accountsContact) {
      if (accountsContact.accountsName.length > 0) {
        errorArray.push(validateText(accountsContact.accountsName))
      }
      if (accountsContact.accountsEmail.length > 0) {
        errorArray.push(validateEmail(accountsContact.accountsEmail))
      }
      if (accountsContact.accountsPhone.length > 0) {
        errorArray.push(validatePhone(accountsContact.accountsPhone))
      }
    }
    if (termsAndConditionsUrl) {
      errorArray.push(validateText(termsAndConditionsText))
    }
    errorArray.push(validateText(termsAndConditionsText))
    
    validationResult = errorChecker(errorArray)
  }

  const validateFitterInfo = (fitter) => {
    const {
      fitterName,
      address1,
      address2,
      address3,
      city,
      county,
      postcode,
      bookingEmail,
      bookingContactNumber,
      wheelSizeLimitsInchesDiameter,
      googleBusinessUrl,
      contactName,
      stapletonsID
    } = fitter;

    const errorArray = [];

    errorArray.push(validateText(fitterName))
    errorArray.push(validateText(address1))
    if (address2) {
      errorArray.push(validateText(address2))
    }
    if (address3) {
      errorArray.push(validateText(address3))
    }
    errorArray.push(validateText(city))
    errorArray.push(validateText(county))
    errorArray.push(validatePostcode(postcode))
    errorArray.push(validateEmail(bookingEmail.value))
    errorArray.push(validatePhone(bookingContactNumber.value))
    errorArray.push(validateWheelSizes(wheelSizeLimitsInchesDiameter))
    errorArray.push(validateNumber(wheelSizeLimitsInchesDiameter.min))
    errorArray.push(validateNumber(wheelSizeLimitsInchesDiameter.max))
    if (googleBusinessUrl) {
      errorArray.push(validateText(googleBusinessUrl))
    }
    errorArray.push(validateText(contactName))
    errorArray.push(validateText(stapletonsID))

    validationResult = errorChecker(errorArray)
  }

  switch(stepName) {
    case "partner":
      validatePartnerInfo(partner)
      break;
    case "fitter":
      validateFitterInfo(fitter)
      break;
    default:
      validationResult = true;
  };

  return validationResult;
};

export default verifyFields;