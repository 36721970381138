import axios from 'axios';

import auth0Client from '../Auth';

const { REACT_APP_SERVER_URL } = process.env;
const url = (REACT_APP_SERVER_URL && REACT_APP_SERVER_URL.toString()) || '';

/*
  TransformRequest is used below because it fixes authentication
  Token isn't always available on page load, if for example the user
  isn't logged in, the token is also refreshed if the user is logged in.
*/

const api = axios.create({
  baseURL: url,
  timeout: 20000, // Lambdas can take up to ~15 seconds to 'warm up'
  transformRequest: [
    (data, headers) => {
      // eslint-disable-next-line no-param-reassign
      headers['Content-Type'] = headers['Content-Type'] || 'application/json';
      // eslint-disable-next-line no-param-reassign
      headers.Authorization = `Bearer ${auth0Client.getIdToken()}`;
      return JSON.stringify(data);
    }
  ]
});

export default api;
