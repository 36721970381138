/* @flow */
import { message } from 'antd';
import api from './api';

const checkSignUp = async (
  auth0ID: string,
  stapletonsID?: string
): Promise<Object> => {
  try {
    const url = `/partner/${auth0ID}/check`
    //JPN - Safari fails to encode the pipe properly in reqs, all authentication ids returned from google SSO and auth0 contain a pipe
    const urlSafeForSafari = url.replace("|", "%7C")
    const res = await api.get(urlSafeForSafari, {
      stapletonsID
    });
    return res.data;
  } catch (error) {
    message.error('Could not check sign up');
    console.error('e', error.response)
    throw (error);
  }
};

export default checkSignUp;
