import React, { useState, useEffect }from "react";
import styles from "./FitterCardBack.module.scss";

const FitterCardBack = ({fitter}) => {
  const { 
    fitterName,
    address1,
    address2,
    address3,
    postcode,
    city,
    county,
    contactName,
    googleBusinessUrl,
    // offset,
    newOpeningTimes,
    slotType,
    bookingEmail,
    bookingContactNumber,
    wheelSizeLimitsInchesDiameter,
    stapletonsID
    } = fitter;

    const [additionalServices, updateAdditionalServices] = useState([]);
    const [accessibleFacilities, updateAccessibleFacilities] = useState([]);
    const [facilities, updateFacilities] = useState([]);
    const [additionalTyreServices, updateAdditionalTyreServices] = useState([]);
    const [trainedStaff, updateTrainedStaff] = useState([]);

    const getDayOfTheWeek = (dayNumber) => {

      let day;
      switch(dayNumber.toString()) {
        case "1":
          day = "Monday";
          break;
        case "2":
          day = "Tuesday";
          break;
        case "3":
          day = "Wednesday";
          break;
        case "4":
          day = "Thursday";
          break;
        case "5":
          day = "Friday";
          break;
        case "6":
          day = "Saturday";
          break;
        case "0":
          day = "Sunday";
          break;
        case "8":
          day = "Bank Holiday";
          break;
        default:
          day = "Day of the week";
          break;
      }
      return day
    }

    const checkIfClosed = (day) => {
      return day.isClosed === true ? "CLOSED" : `Open: ${day.open} | Close: ${day.close}`
    }
  
    const checkForAdditionalInfo = (fitter: object) => {
      if (
        fitter.hasOwnProperty('additionalInformation')
        ) {
          updateAccessibleFacilities(fitter.additionalInformation.accessibleFacilities);
          updateAdditionalServices(fitter.additionalInformation.additionalServices);
          updateAdditionalTyreServices(fitter.additionalInformation.additionalTyreServices);
          updateFacilities(fitter.additionalInformation.facilities);
          updateTrainedStaff(fitter.additionalInformation.trainedStaff);
        } else {
        };
    };

    useEffect(() => checkForAdditionalInfo(fitter), [fitter])

  return (
    <section className={styles.FitterCardBack}>
      <p><span>Name:</span> {fitterName}</p>
      <p><span>Address1:</span> {address1}</p>
      <p><span>Address2:</span> {address2}</p>
      <p><span>Address3:</span> {address3 ? address3 : "None"}</p>
      <p><span>City:</span> {city}</p>
      <p><span>County:</span> {county}</p>
      <p><span>Postcode:</span> {postcode.toUpperCase()}</p>
      <p><span>Contact name:</span> {contactName}</p>
      <p><span>Stapletons ID:</span> {stapletonsID}</p>
      <p><span>googleBusinessUrl:</span> {googleBusinessUrl ? googleBusinessUrl: "None"}</p>
      {/* <p><span>Your turnaround time for bookings:</span> {offset} day(s)</p> */}
      <p><span>Wheel sizes fitted (diameter)</span> Min: {wheelSizeLimitsInchesDiameter.min} inches | Max: {wheelSizeLimitsInchesDiameter.max} inches</p>
      <p><span>Opening times:</span></p>
      {newOpeningTimes.map(day => (
        <p key={day.day}><span>{getDayOfTheWeek(day.day)}</span> | {checkIfClosed(day)}</p>
      ))}
      <p><span>Slot type:</span> {slotType}</p>
      <p><span>Bookings email:</span> {bookingEmail.value}</p>
      <p><span>Bookings contact no:</span> {bookingContactNumber.value}</p>
      <div>
        <p><span>Additional Info:</span></p>
        <div>
          <p><span>Accessible Facilities:</span></p>
          <ul>
          {accessibleFacilities.map(item => (
            <li key={item}>{item}</li>
          ))}
          </ul>
        </div>
        <div>          
          <p><span>About Your Premises:</span></p>
            <ul>
            {additionalServices.map(item => (
              <li key={item}>{item}</li>
            ))}
            </ul>
        </div>
        <div>          
          <p><span>Facilities:</span></p>
            <ul>
            {facilities.map(item => (
              <li key={item}>{item}</li>
            ))}
            </ul>
        </div>
        <div>          
          <p><span>Tyre Services:</span></p>
            <ul>
            {additionalTyreServices.map(item => (
              <li key={item}>{item}</li>
            ))}
            </ul>
        </div>
        <div>          
          <p><span>Memberships and qualifications:</span></p>
            <ul>
            {trainedStaff.map(item => (
              <li key={item}>{item}</li>
            ))}
            </ul>
        </div>
      </div>
    </section>
  );
};

export default FitterCardBack;
