/* @flow */

// $FlowFixMe
import styles from './NavLocation.module.scss';

import * as React from 'react';
import Breadcrumb from '../Breadcrumb'

const NavLocation = (location: string, showHome: string) => {
  return (
    <div className={styles.navLocationContainer}>
      <div className={styles.navLocation}>
      <div className={styles.location}>{location}</div>
      <div className={styles.breadcrumb}>
        {showHome && showHome !== "signedUp" ? <Breadcrumb journeyStage={showHome}/> : null}
      </div>
    </div>
    </div>
    
  );
};

export default NavLocation;
