import LoadingModal from '@motokiki/shared/components/LoadingModal/LoadingModal';
import React from 'react';

import styles from './Home.module.scss';

import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import NavLocation from '../../components/navLocation';
import setLocation from '../../methods/setLocation';
// check if they have signed up, if they have them show them the homepage.
type State = {
  showHome: Boolean,
  auth0profile: Object,
  partnerInfo: Object,
  isLoading: Boolean
};

type Props = {
  location: { search: string },
  history: any
};

class Home extends React.Component<Props, State> {
  state = {
    showHome: false,
    isLoading: true
    // auth0profile: {}
  };

  componentDidMount() {
    this.setState({ showHome: false });
    this.setUpHome();
  }

  setUpHome = async () => {
    const { history } = this.props;
    const auth0profile = await auth0Client.getProfile();
    const auth0ID = auth0profile.sub;
    const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
    // this.setState({ auth0profile });
    if (showHome[1] === 'signedUp') {
      this.setState({ showHome: true, partnerInfo: showHome[0] });
    } else {
      setLocation(history.location.pathname, showHome[1], history);
    }
  };

  render() {
    const { showHome, partnerInfo, isLoading } = this.state;
    return (
      <div>
        {NavLocation(`${partnerInfo && partnerInfo.name}'s dashboard`)}
        <div
          style={{
            padding: '30px 40px',
            maxWidth: '1071px',
            margin: 'auto'
          }}
        >
          {showHome && partnerInfo ? (
            <div>
              <div
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                {partnerInfo && partnerInfo.isActiveOnPlatform ? 
                <div className={styles.promptBox}>
                  <h2>Stapleton's have verified your information</h2>
                  <p>
                    You are now live on the Motokiki platform and can manage your details with your dashboard.
                  </p>
                  <p>
                    Please <a href='mailto:contact@motokiki.com'>contact us</a> if you have any issues with your account.
                  </p>
                </div>
                  : 
                  <div className={styles.promptBox}>
                    <h2>You've done everything required for Motokiki</h2>
                    <p>
                      We are waiting for Stapleton's to verify your details before you are activated on the main platform. We aim for this to happen within 5 working days.
                    </p>
                    <p>
                      You can review your details, and amend them using the tabs across the top of your dashboard.
                    </p>
                </div>}
                <div className={styles.promptBox}>
                  <h2>Improve your customers' experience with Stripe</h2>
                  <p>
                    While you wait for verification, why not improve the experience of your customers on Stripe, by making sure <a href="https://partnerkiki-static-assets.s3.eu-west-2.amazonaws.com/stripe-pdfs/adding-logo-to-stripe-infographic.pdf" target="_blank" rel="noopener noreferrer">you've added your logo to your Stripe account?</a>
                  </p>
                  <p>
                    Log in to your <a href="https://dashboard.stripe.com/settings/user" target="_blank" rel="noopener noreferrer">Stripe account</a> to update it.
                  </p>
                </div>
                <div className={styles.promptBox}>
                  <h2>Are your Motokiki prices correct?</h2>
                  <p>
                    Have you set your prices for Motokiki with Stapletons? It's important to have the correct information for us to display.
                  </p>
                  <p>
                    You can manage this through your Stapletons' Profitlink account.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <LoadingModal isOpen={isLoading} loadingText="Loading your data..." />
          )}
        </div>
      </div>
    );
  }
}

export default Home;