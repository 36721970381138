import React from 'react';
import { ReactComponent as Auth0Logo } from '../../assets/auth0.svg';
import './footer2.scss';

const Footer2 = () => {
  const year = new Date().getFullYear();
  return (
    <footer className='footer2'>
      <div
        className='footer2__content-box'
        style={{
          padding: '30px 40px',
          maxWidth: '1071px',
          margin: 'auto'
        }}
      >
        <hr />
        <div className='footer2__content-box__company'>
          {`${year} Motokiki Ltd. All rights reserved.`}
        </div>
        <div className='footer2__content-box__link-box'>
          <ul>
            <li>
              <a href='https://www.motokiki.com/privacy'>
                Terms and Conditions
              </a>
            </li>
            <li>
              <a href='https://www.motokiki.com/privacy'>Privacy Policy</a>
            </li>
            <li>
              <a href='https://www.okta.com/privacy-policy'>
                <Auth0Logo />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
