import React from 'react';

import './Stripe.scss';
import { ReactComponent as StripeLogo } from '../../assets/stripe-logo.svg';
import { Button } from 'antd';

export const StripeButton = ({ loading, text, form }) => {
  return (
    <Button
      htmlType='submit'
      type='mk'
      form={form}
      size='large'
      shape='round'
      className='rebrand-primary stripe-btn'
      loading={loading}
    >
      {text}Connect with <StripeLogo />
    </Button>
  );
};

export default StripeButton;
