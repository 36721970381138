/* @flow */
import { message } from 'antd';

import api from './api';
import auth0Client from '../Auth';

const partner = {
  create: async (stapletonsID: string): Promise<Object> => {
    const newStapletonsID = stapletonsID || '';
    const auth0profile = await auth0Client.getProfile();
    const { sub } = auth0profile;
    try {
      const res = await api.post(`/partner`, {
        stapletonsID: newStapletonsID,
        sub
      });
      return res;
    } catch (error) {
      message.error('Error adding new partner');
      console.error('e', error.response);
      throw (error);
    }
  },
  update: async (values: any): Promise<Object> => {
    if (!values) {
      throw new Error('Missing values');
    }
    const data = { values };
    const _id = (values && values._id) || 'none';
    try {
      const res = await api.put(`/partner/${_id}`, { data });
      return res;
    } catch (error) {
      message.error('Error updating partner');
      console.error('e', error.response);
      throw (error);
    }
  },
  getPartner: async (partnerID: string): Promise<Object> => {
    if (!partnerID) {
      throw new Error('Missing partner ID');
    }
    try {
      const res = await api.get(`/partner/getPartner/${partnerID}`)
      return res.data;
    } catch (error) {
      message.error('Error retrieving partner')
      console.error('e', error.response);
      throw (error)
    }
  },
  getFitters: async (partnerID: string): Promise<Object> => {
    if (!partnerID) {
      throw new Error('Missing partnerID');
    }
    try {
      const res = await api.get(`/partner/getFitters/${partnerID}`, {});
      return res.data;
    } catch (error) {
      message.error('Error finding fitters');
      console.error('e', error.response);
      throw (error);
    }
  },
  createPartnerOrderForm: async (partnerID: string): Promise<Object> => {
    if (!partnerID) {
      throw new Error('Missing partnerID');
    }
    try {
      const res = await api.get(`/partner/${partnerID}/createPartnerOrderForm`);
      return res.data;
    } catch (error) {
      message.error("Error creating partner order form");
      console.error('e', error.response);
      throw (error);
    }
  },
  createFitterLocationFile: async (partnerID: string): Promise<Object> => {
    if (!partnerID) {
      throw new Error('Missing partnerID');
    }
    try {
      const res = await api.get(`/partner/createFitterLocationFile/${partnerID}`);
      return res.data;
    } catch (error) {
      message.error("Error creating FitterLocation file");
      console.error('e', error.response);
      throw (error);
    }
  }

};

export default partner;