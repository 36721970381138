import { Form, Button, Switch, message, notification, Row, Upload } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserDeleteOutlined
} from '@ant-design/icons';
import Input from '@motokiki/shared/input';
import Label from '@motokiki/shared/label';
import LoadingModal from '@motokiki/shared/components/LoadingModal/LoadingModal';
import Wysiwyg from '@motokiki/shared/components/Wysiwyg';
import Tooltip from '@motokiki/shared/tooltip';
import InfoCircle from '@ant-design/icons/InfoCircleOutlined';
import styles from './ManagePartnerScene.module.scss';
import React from 'react';
import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import partnerService from '../../services/partner';
import verifyFields from '../../methods/verifyFields';
// $FlowFixMe
import NavLocation from '../../components/navLocation';
import StripeButton from '../../components/OfficalStripeButton';

type Props = {
  form: {
    validateFields: (err: any, values: any) => any,
    getFieldDecorator: (name: string, options: any) => any
  },
  history: {
    push: (url: string) => void,
    location: Object
  }
};

type State = {
  step: number,
  auth0profile: Object,
  stapletonsData: Object,
  logoFile: any,
  preview: boolean,
  imageLocation: String,
  isLoading: Boolean,
  logoImageFile: {
    loading: Boolean,
    imageUrl: String
  },
  partner: {
    name: String,
    contactEmail: String,
    phoneNumber: String,
    logo: any,
    _id: string,
    stapletonsID: string,
    networkId: string,
    companyRegistrationNumber: string,
    companyVatNumber: string,
    contactName: string,
    termsAndConditionsText: string,
    termsAndConditionsUrl: string,
    address1: string,
    address2: string,
    address3: string,
    city: string,
    county: string,
    postcode: string,
    offerPayInStore: Boolean,
    offerPayOnline: Boolean,
    offerDelivery: Boolean,
    offerFitting: Boolean,
    offerMobile: Boolean
  }
};

function openStripeNotification() {
  notification.info({
    message: 'Important!',
    description:
      'To use the online payment option, you will need to link your Stripe account.'
  });
}

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class ManagePartnerScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auth0profile: {},
      logoFile: null,
      preview: false,
      imageLocation: '',
      isLoading: true,
      logoImageFile: { loading: false, imageUrl: null }
    };
  }

  componentDidMount() {
    this.setUpForm();
  }

  onLogoChange = info => {
    const file = info.file;
    const isSupported =
      ['image/png', 'image/jpg', 'image/jpeg'].indexOf(file.type) !== -1;

    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isSupported) {
      message.error(`${file.name} is not a jpg or png file`);
    }
    if (!isLt2M) {
      message.error(`${file.name} is bigger than 2Mb`);
    }
    if (isLt2M && isSupported) {
      getBase64(info.file, imageUrl =>
        this.setState({ logoImageFile: { loading: false, imageUrl } })
      );
    }
  };

  handleSubmit = async (e: Event) => {
    e.preventDefault();
    const { partner, auth0profile, imageLocation, logoImageFile } = this.state;
    //store the original partner logo bucket location before local partner.logo state mutates to image file to be uploaded
    const storedLogoLocation = imageLocation;
    const logoFile = logoImageFile ? logoImageFile.imageUrl : null;
    if (verifyFields({}, partner, message, 'partner') === true) {
      let newPartnerData = partner;
      
      if (logoFile !== null) {
        newPartnerData.logo = logoFile;
      }

      try {
        this.setModal(true);
        const haveWeUpdatedPartner = await partnerService.update({
          auth0ID: auth0profile.sub,
          ...newPartnerData
        });

        if (haveWeUpdatedPartner) {
          message.success('Success updating partner');
          //if partner needs to be connected with stripe
          if (partner.offerPayOnline && !partner.stripeAccountId) {
            const {
              REACT_APP_REDIRECT_URL,
              REACT_APP_STRIPE_CLIENT_ID
            } = process.env;
            let url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${REACT_APP_STRIPE_CLIENT_ID}&scope=read_write`;

            url = `${url}&stripe_user[street_address]=${partner.address1}&stripe_user[city]=${partner.city}&stripe_user[zip]=${partner.postcode}&stripe_user[country]=GB&stripe_user[email]=${partner.contactEmail}&stripe_user[business_name]=${partner.name}&stripe_user[business_type]=company&redirect_uri=${REACT_APP_REDIRECT_URL}`;
            window.location.href = url;
          }

          //force a state update in image to rerender the new logo from the s3 bucket
          this.setState({
            imageLocation: `${storedLogoLocation}?v=${new Date()}`
          });
          this.setModal(false);
        } else {
          this.setModal(false);
          message.error('Failed to update partner details');
        }
      } catch (err) {
        this.setModal(false);
        console.error('e: ', err.response);
        message.error(`Error updating partner: ${err.response.data.error}`);
      }
    } else {
      message.error('Please check your information and resubmit');
    }
  };

  setModal = modalState => {
    this.setState({
      isLoading: modalState
    });
  };

  setUpForm = async () => {
    const auth0Profile = await auth0Client.getProfile();
    const auth0ID = auth0Profile.sub;
    const showHome = await checkSignUp(auth0ID);
    const partner = showHome[0];
    this.setState({
      partner: partner,
      stapletonsData: showHome[0],
      auth0profile: auth0Profile,
      imageLocation: `${partner.logo}?v=${new Date()}`,
      logoImageFile: { loading: false, imageUrl: null }
    });
    this.setModal(false);
  };

  handleInput = (event, ob, name, anotherLayer) => {
    const value = event.target.value;
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name];
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: {
          ...val,
          [`${name}`]: { ...valDeeper, [`${anotherLayer}`]: value }
        }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  handleToggle = (value, ob, name, anotherLayer) => {
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name];
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: {
          ...val,
          [`${name}`]: { ...valDeeper, [`${anotherLayer}`]: value }
        }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  handleWysiwyg = (event, ob, name, anotherLayer) => {
    const value = event;
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name];
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: {
          ...val,
          [`${name}`]: { ...valDeeper, [`${anotherLayer}`]: value }
        }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  generateAccInfoJSX = accInfo => {
    const { partner } = this.state;
    const accInfoJSX = accInfo ? (
      <div>
        <Row>
          <Label>
            Accounts Email (If different)
            <Input
              size='large'
              placeholder='email@email.com'
              value={partner.accountsContact.accountsEmail}
              onChange={e =>
                this.handleInput(
                  e,
                  'partner',
                  'accountsContact',
                  'accountsEmail'
                )
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <Label>
            Accounts Phone number (If different)
            <Input
              size='large'
              placeholder='01189000000'
              value={partner.accountsContact.accountsPhone}
              onChange={e =>
                this.handleInput(
                  e,
                  'partner',
                  'accountsContact',
                  'accountsPhone'
                )
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <Label>
            Accounts Name of contact (If applicable)
            <Input
              size='large'
              placeholder='Name of accounts contact'
              value={partner.accountsContact.accountsName}
              onChange={e =>
                this.handleInput(
                  e,
                  'partner',
                  'accountsContact',
                  'accountsName'
                )
              }
            />
          </Label>
        </Row>
        <Button
          shape='round'
          type='secondary'
          onClick={() => {
            this.toggleAccInfo(!this.state.accInfo);
          }}
        >
          <UserDeleteOutlined /> Remove contact
        </Button>
      </div>
    ) : null;
    return accInfoJSX;
  };

  toggleAccInfo = newState => {
    this.setState({
      accInfo: newState
    });
  };

  render() {
    const { partner, imageLocation, isLoading } = this.state;

    return (
      <div>
        {NavLocation('Manage partner details')}
        <div className={styles.container}>
          {partner ? (
            <div className={styles.mainContent}>
              {/* JPN: get latest image rather than browser cached image on below line */}
              <div className={styles.nonForm}>
                {partner.logo !== 'unset' ? (
                  <div className={styles.imageCont}>
                    <img
                      src={imageLocation}
                      alt='partner logo'
                      className={styles.logoImage}
                    />
                  </div>
                ) : null}
                <h3>Your documnets</h3>
                <div>
                  <div className={styles.linkButtonContainer}>
                    <Button
                      type='mk'
                      size='large'
                      shape='round'
                      className='rebrand-primary stripe-btn'
                    >
                      <a
                        href={partner.documents.orderForm.fileUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={styles.linkButton}
                      >
                        Your Motokiki Agreement <FilePdfOutlined />
                      </a>
                    </Button>
                  </div>
                  <div className={styles.linkButtonContainer}>
                    <Button
                      type='mk'
                      size='large'
                      shape='round'
                      className='rebrand-primary stripe-btn'
                    >
                      <a
                        href={partner.documents.retailerContract.fileUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={styles.linkButton}
                      >
                        Your Motokiki contract <FilePdfOutlined />
                      </a>
                    </Button>
                  </div>
                  <div className={styles.linkButtonContainer}>
                    <Button
                      type='mk'
                      size='large'
                      shape='round'
                      className='rebrand-primary stripe-btn'
                    >
                      <a
                        href={partner.documents.retailerMotokikiTerms.fileUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                        className={styles.linkButton}
                      >
                        Motokiki T&Cs <FilePdfOutlined />
                      </a>
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <Form id='partner-form' onSubmit={this.handleSubmit}>
                  <div>
                    <h3>Contact information</h3>
                    <Label>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Partner Name*</p>
                        <Tooltip
                          placement='right'
                          targetId='partnerName'
                          tooltipText='If you need to change this, please contact us'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        disabled
                        size='large'
                        placeholder='Core business name'
                        value={partner.name}
                        onChange={e => this.handleInput(e, 'partner', 'name')}
                      />
                    </Label>
                    <Row style={{ paddingTop: '20px' }}>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Business email address*
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='businessEmail'
                            tooltipText='Your business email (standard email format, mandatory)'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          placeholder='Core business email'
                          value={partner.contactEmail}
                          onChange={e =>
                            this.handleInput(e, 'partner', 'contactEmail')
                          }
                        />
                      </Label>
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Business phone number*
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='businessPhone'
                            tooltipText='Your business phone number (mandatory)'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          placeholder='01189000000'
                          value={partner.phoneNumber}
                          onChange={e =>
                            this.handleInput(e, 'partner', 'phoneNumber')
                          }
                        />
                      </Label>
                    </Row>
                    <h3>Business address</h3>
                    <Row style={{ paddingTop: '20px' }}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Address line 1*</p>
                        <Tooltip
                          placement='bottom'
                          targetId='address1'
                          tooltipText='Your business address (mandatory)'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size='large'
                        placeholder='123 Your Road'
                        value={partner.address1}
                        onChange={e =>
                          this.handleInput(e, 'partner', 'address1')
                        }
                      />
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Address line 2*</p>
                        <Tooltip
                          placement='bottom'
                          targetId='address2'
                          tooltipText='Your business address (mandatory)'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size='large'
                        placeholder='Your Area'
                        value={partner.address2}
                        onChange={e =>
                          this.handleInput(e, 'partner', 'address2')
                        }
                      />
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Address line 3</p>
                        <Tooltip
                          placement='bottom'
                          targetId='address3'
                          tooltipText='Your business address'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size='large'
                        placeholder=''
                        value={partner.address3}
                        onChange={e =>
                          this.handleInput(e, 'partner', 'address3')
                        }
                      />
                    </Row>
                    <Row
                      style={{ paddingTop: '20px' }}
                      className={styles.smaller}
                    >
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>City*</p>
                        <Tooltip
                          placement='bottom'
                          targetId='addressCity'
                          tooltipText='Your business address (mandatory)'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size='large'
                        placeholder='Your City'
                        value={partner.city}
                        onChange={e => this.handleInput(e, 'partner', 'city')}
                      />
                    </Row>
                    <Row
                      style={{ paddingTop: '20px' }}
                      className={styles.smaller}
                    >
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>County*</p>
                        <Tooltip
                          placement='bottom'
                          targetId='addressCounty'
                          tooltipText='Your business address (mandatory)'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size='large'
                        placeholder='Your County'
                        value={partner.county}
                        onChange={e => this.handleInput(e, 'partner', 'county')}
                      />
                    </Row>
                    <Row
                      style={{ padding: '20px 0' }}
                      className={styles.smaller}
                    >
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>Postcode*</p>
                        <Tooltip
                          placement='bottom'
                          targetId='postcode'
                          tooltipText='Your business postcode (mandatory, standard UK format)'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <Input
                        size='large'
                        placeholder='AB12CD'
                        value={partner.postcode}
                        onChange={e =>
                          this.handleInput(e, 'partner', 'postcode')
                        }
                      />
                    </Row>
                    <Row
                      style={{ paddingTop: '20px' }}
                      className={styles.smaller}
                    >
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Company registration number
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='companyReg'
                            tooltipText='Your Company Registration No.'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          placeholder='Company reg number'
                          value={partner.companyRegistrationNumber}
                          onChange={e =>
                            this.handleInput(
                              e,
                              'partner',
                              'companyRegistrationNumber'
                            )
                          }
                        />
                      </Label>
                    </Row>
                    <Row
                      style={{ paddingTop: '20px' }}
                      className={styles.smaller}
                    >
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Company VAT number*
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='vatNo'
                            tooltipText='Your company VAT no. (mandatory)'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          placeholder='Company VAT number'
                          value={partner.companyVatNumber}
                          onChange={e =>
                            this.handleInput(e, 'partner', 'companyVatNumber')
                          }
                        />
                      </Label>
                    </Row>
                    <h3>Payment options</h3>
                    <Row style={{ paddingTop: '20px' }}>
                      <div className={styles.tooltipLabelBox}>
                        <p className={styles.tooltipLabel}>
                          Select payment options
                        </p>
                        <Tooltip
                          placement='bottom'
                          tooltipText='Select at least one method'
                        >
                          <InfoCircle />
                        </Tooltip>
                      </div>
                      <div className={styles.fitterOptionsCont}>
                        <div className={styles.fitterOption}>
                          <p>Offer pay in store</p>
                          <Switch
                            checked={partner.offerPayInStore}
                            onChange={checked => {
                              if (!partner.offerPayOnline) {
                                openStripeNotification();
                              }
                              this.setState({
                                partner: {
                                  ...partner,
                                  offerPayInStore: checked,
                                  offerPayOnline: true
                                }
                              });
                            }}
                          />
                        </div>
                        <div className={styles.fitterOption}>
                          <p>Offer pay online</p>
                          <Switch
                            checked={partner.offerPayOnline}
                            onChange={checked => {
                              if (checked) {
                                openStripeNotification();
                              }
                              this.setState({
                                partner: {
                                  ...partner,
                                  offerPayInStore: true,
                                  offerPayOnline: checked
                                }
                              });
                            }}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Select Tyre fitting/delivery options
                          </p>
                        </div>
                        <div className={styles.fitterOptionsCont}>
                          <div className={styles.fitterOption}>
                            <p>On site Fitting</p>
                            <Switch
                              checked={partner.offerFitting}
                              onChange={checked => {
                                this.handleToggle(
                                  checked,
                                  'partner',
                                  'offerFitting'
                                );
                              }}
                            />
                          </div>
                          {/* <div className={styles.fitterOption}>
                                <p>Delivery only</p>
                                <Toogle
                                isChecked={partner.offerDelivery}
                                  onUpdate={e => this.handleToggle(e, 'partner', 'offerDelivery' )}
                                />
                              </div>
                              <div className={styles.fitterOption}>
                                <p>Mobile Fitting</p>
                                <Toogle
                                isChecked={partner.offerMobile}
                                  onUpdate={e => this.handleToggle(e, 'partner', 'offerMobile'  )}
                                />
                              </div> */}
                        </div>
                      </Label>
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Name of contact*
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='contactName'
                            tooltipText='Name of a contact between your business and Motokiki (mandatory)'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          placeholder='Name'
                          value={partner.contactName}
                          onChange={e =>
                            this.handleInput(e, 'partner', 'contactName')
                          }
                        />
                      </Label>
                    </Row>
                    <Row
                      style={{ paddingTop: '20px' }}
                      className={styles.smaller}
                    >
                      {partner.stripeAccountId && (<Label>
                          <div className={styles.tooltipLabelBox}>
                            <p className={styles.tooltipLabel}>Stripe Account ID*</p>
                            <Tooltip placement="bottom" targetId="stripeID" tooltipText="Your Stripe Account ID (mandatory)">
                              <InfoCircle />
                            </Tooltip>
                          </div>
                          <Input
                            size="large"
                            placeholder="Stripe account ID"
                            value={partner.stripeAccountId}
                            onChange={e => this.handleInput(e, 'partner', 'stripeAccountId')}
                          />
                        </Label> )}
                    </Row>
                    <h3>Accounts contact info (if different)</h3>
                    {!this.state.accInfo && (
                      <Button
                        shape='round'
                        type='secondary'
                        onClick={() => {
                          this.toggleAccInfo(!this.state.accInfo);
                        }}
                      >
                        <UserAddOutlined /> Add contact
                      </Button>
                    )}
                    <Row style={{ paddingTop: '20px' }}>
                      {this.generateAccInfoJSX(this.state.accInfo)}
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <h3>Your company logo</h3>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Please upload a copy of your company’s logo
                            (optional).
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='logoImage'
                            tooltipText='Your logo image, will be cropped to a square shape. Accepted file types are GIF, JPEG, PNG or SVG.'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                      </Label>
                      <Upload
                        beforeUpload={() => false}
                        className='svg_overlay_container'
                        accept='.jpeg,.jpg,.png'
                        name='logoImageFile'
                        showUploadList={false}
                        onChange={this.onLogoChange}
                      >
                        {this.state.logoImageFile.imageUrl ? (
                          <>
                            <div className='svg_overlay'>
                              <EditOutlined className='svg_overlay__icon'/>
                              <DeleteOutlined 
                                onClick={(e)=> {
                                  e.stopPropagation();
                                  this.setState({ logoImageFile: { loading: false, imageUrl: null } })
                                }}
                                className='svg_overlay__icon'
                              />
                            </div>
                            <img
                              src={this.state.logoImageFile.imageUrl}
                              alt='avatar'
                              style={{ maxWidth: '200px', maxHeight: '200px' }}
                            />
                          </>
                        ) : (
                          <Button shape='round' type='secondary'>
                            <UploadOutlined />
                            Click to Upload
                          </Button>
                        )}
                      </Upload>
                    </Row>
                    <Row
                      style={{ paddingTop: '20px' }}
                      className={styles.smaller}
                    >
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Stapletons account number*
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='stapletonsNo'
                            tooltipText='If you need to change this please contact us directly'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          placeholder="Stapleton's Acc. No."
                          value={partner.stapletonsID}
                          onChange={e =>
                            this.handleInput(e, 'partner', 'stapletonsID')
                          }
                          disabled='disable'
                        />
                      </Label>
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Terms and conditions URL (if you have them hosted
                            externally)
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='tAndCUrl'
                            tooltipText='If you have your terms and conditions hosted externally, you can input the URL here'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Input
                          size='large'
                          value={partner.termsAndConditionsUrl}
                          onChange={e =>
                            this.handleInput(
                              e,
                              'partner',
                              'termsAndConditionsUrl'
                            )
                          }
                        />
                      </Label>
                    </Row>
                    <Row style={{ paddingTop: '20px' }}>
                      <Label>
                        <div className={styles.tooltipLabelBox}>
                          <p className={styles.tooltipLabel}>
                            Your terms and conditions*
                          </p>
                          <Tooltip
                            placement='bottom'
                            targetId='tAndCs'
                            tooltipText='Your business terms and conditions (mandatory)'
                          >
                            <InfoCircle />
                          </Tooltip>
                        </div>
                        <Wysiwyg
                          placeholder='Your terms and conditions here'
                          value={partner.termsAndConditionsText}
                          onChange={e =>
                            this.handleWysiwyg(
                              e,
                              'partner',
                              'termsAndConditionsText'
                            )
                          }
                        />
                      </Label>
                    </Row>
                    <Row style={{ paddingTop: '20px' }} className='mk-center'>
                      {partner.offerPayOnline && !partner.stripeAccountId ? (
                        <StripeButton form='partner-form' text='Update and ' />
                      ) : (
                        <Button
                          form='partner-form'
                          htmlType='submit'
                          type='mk'
                          size='large'
                          shape='round'
                          className='rebrand-primary stripe-btn'
                          loading={isLoading}
                        >
                          Update information
                        </Button>
                      )}
                    </Row>
                  </div>
                </Form>
              </div>
            </div>
          ) : (
            <LoadingModal
              isOpen={isLoading}
              loadingText='Loading your business data...'
            />
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(ManagePartnerScene);
