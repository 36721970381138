/* @flow */

import auth0Js from 'auth0-js';
import axios from 'axios';

const { REACT_APP_SERVER_URL } = process.env;
const url = (REACT_APP_SERVER_URL && REACT_APP_SERVER_URL.toString()) || '';

class Auth {
  auth0: auth0Js.WebAuth;

  clientId: string;

  redirectUrl: string;

  heartBeatURL: string;

  logoutURL: string;

  invalidateURL: string;

  renewTokenTimer: any;

  heartBeatTimer: any;

  hasBeenLoggedIn: boolean;

  getProfile: () => any;

  getIdToken: () => any;

  getExpiresAt: () => any;

  getLastApiCall: () => any;

  handleAuthentication: () => any;

  renewToken: () => any;

  isAuthenticated: () => void;

  signIn: () => void;

  signOut: () => void;

  silentAuth: (force?: boolean) => any;

  getHeartBeatURL: () => any;

  getLogoutURL: () => any;

  getInvalidateURL: () => any;

  updateLastApiCall: () => any;

  constructor() {
    const {
      REACT_APP_AUTH0_DOMAIN,
      REACT_APP_AUTH0_CLIENT,
      REACT_APP_REDIRECT_URL
    } = process.env;

    this.auth0 = new auth0Js.WebAuth({
      domain: REACT_APP_AUTH0_DOMAIN || '',
      audience: `https://${REACT_APP_AUTH0_DOMAIN || ''}/userinfo`,
      clientID: REACT_APP_AUTH0_CLIENT || '',
      redirectUri: REACT_APP_REDIRECT_URL || '',
      responseType: 'token id_token',
      scope: 'openid profile email',
    });

    this.clientId = REACT_APP_AUTH0_CLIENT || '';
    this.redirectUrl = REACT_APP_REDIRECT_URL || '';
    this.heartBeatURL = `${url}/auth/heartbeat`;
    this.invalidateURL = `${url}/auth/invalidate`;
    this.logoutURL = `${url}/auth/logout`;
    this.hasBeenLoggedIn = false;

    this.getProfile = this.getProfile.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.getExpiresAt = this.getExpiresAt.bind(this);
    this.getLastApiCall = this.getLastApiCall.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.renewToken = this.renewToken.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.silentAuth = this.silentAuth.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getHeartBeatURL = this.getHeartBeatURL.bind(this);
    this.getLogoutURL = this.getLogoutURL.bind(this);
    this.getInvalidateURL = this.getInvalidateURL.bind(this);
    this.updateLastApiCall = this.updateLastApiCall.bind(this);

    this.renewTokenTimer = setInterval(async () => {
      if (this.isAuthenticated()) {
        await this.renewToken();
      }
    }, 1000 * 60);

    this.heartBeatTimer = setInterval(async () => {
      if (this.isAuthenticated()) {
        const result = await axios.get(this.getHeartBeatURL(), {
          headers: { Authorization: `Bearer ${this.getIdToken()}` }
        });

        if (result.data === true) {
          return true;
        }
      }

      if (
        this.getIdToken() ||
        localStorage.getItem('authLoggedOut') === 'true'
      ) {
        return this.signOut();
      }

      return false;
    }, 1000 * 30);
  }

  // eslint-disable-next-line class-methods-use-this
  getProfile(): any {
    const authProfile = localStorage.getItem('authProfile');
    return (authProfile && JSON.parse(authProfile)) || undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getIdToken(): any {
    return localStorage.getItem('authIdToken') || undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getExpiresAt(): any {
    const expiresAt = localStorage.getItem('authExpiresAt');
    return (expiresAt && Number.parseInt(expiresAt, 10)) || undefined;
  }

  // eslint-disable-next-line class-methods-use-this
  getLastApiCall(): any {
    return localStorage.getItem('lastApiCall') || undefined;
  }

  handleAuthentication(): any {
    return new Promise((resolve, reject) =>
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }

        this.setSession(authResult);
        return resolve();
      })
    );
  }

  async setSession(authResult: any) {
    const storedToken = this.getIdToken();
    if (storedToken && storedToken !== authResult.idToken) {
      await axios.get(this.getInvalidateURL(), {
        headers: { Authorization: `Bearer ${this.getIdToken()}` }
      });
    }

    localStorage.clear();
    localStorage.setItem('authIdToken', authResult.idToken);
    localStorage.setItem(
      'authProfile',
      JSON.stringify(authResult.idTokenPayload)
    );
    // set the time that the id token will expire at
    const expiresAt =
      authResult.idTokenPayload.exp * 1000 || 1020 + new Date().getTime();

    localStorage.setItem('authExpiresAt', expiresAt.toString());

    this.hasBeenLoggedIn = true;
  }

  async signOut() {
    if (this.getIdToken()) {
      await axios.get(this.getLogoutURL(), {
        headers: { Authorization: `Bearer ${this.getIdToken()}` }
      });

      localStorage.clear();
      localStorage.setItem('authLoggedOut', 'true');

      return this.auth0.logout({
        returnTo: this.redirectUrl.replace('/callback', ''),
        clientID: this.clientId
      });
    }

    if (this.hasBeenLoggedIn) {
      window.location.reload();
    }

    return true;
  }

  async silentAuth(force?: boolean): any {
    return new Promise(async (resolve, reject) => {
      if (!this.getIdToken() || force) {
        return this.auth0.checkSession({}, async (err, authResult) => {
          if (err) {
            return reject(err);
          }

          this.setSession(authResult);
          return resolve();
        });
      }
      return resolve();
    });
  }

  async renewToken(): any {
    const date = new Date();
    const expiresAt = new Date(this.getExpiresAt());
    expiresAt.setMinutes(expiresAt.getMinutes() - 2);

    if (date.getTime() > expiresAt.getTime()) {
      const timeCheck = new Date();
      timeCheck.setMinutes(date.getMinutes() - 15);

      if (this.getLastApiCall() > timeCheck.getTime()) {
        await this.silentAuth(true);
      }
    }
    return true;
  }

  isAuthenticated() {
    return new Date().getTime() < this.getExpiresAt();
  }

  signIn() {
    this.auth0.authorize();
  }

  getHeartBeatURL() {
    return this.heartBeatURL;
  }

  getLogoutURL() {
    return this.logoutURL;
  }

  getInvalidateURL() {
    return this.invalidateURL;
  }

  // eslint-disable-next-line class-methods-use-this
  updateLastApiCall() {
    return localStorage.setItem('lastApiCall', new Date().getTime().toString());
  }
}

const auth0Client = new Auth();

export default auth0Client;
