/* @flow */
import { Form, message, Row } from 'antd';
import Button from '@motokiki/shared/button';
import CheckBoxGroup from '@motokiki/shared/checkBoxGroup';
import Input from '@motokiki/shared/input';
import Label from '@motokiki/shared/label';
import LoadingModal from '@motokiki/shared/components/LoadingModal/LoadingModal';
import RadioGroup from '@motokiki/shared/radioGroup';
import TabbedTable from '@motokiki/shared/tabbedTable'
import getDataSource from '@motokiki/shared/components/BookingWidgetUtils/getDataSource'
import bookingSlot from '@motokiki/shared/components/BookingWidgetUtils/BookingSlot'
import {
  transformBookingSlotsForTabbedTable,
  getOpeningTimesFromFitterObject,
  getModeBookingsFromFitterObject,
  getDaysStateFromFitterObject,
} from '@motokiki/shared/components/BookingWidgetUtils/bookingWidgetUtils'
import columns from '@motokiki/shared/components/BookingWidgetUtils/tableColumns';

import Tooltip from '@motokiki/shared/tooltip';
import InfoCircle from '@ant-design/icons/InfoCircleOutlined';
import moment from 'moment';
import React from 'react';
import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
import partnerService from '../../services/partner'
import fitterService from '../../services/fitter';
import emailService  from '../../services/email';
import verifyFields from '../../methods/verifyFields';
// $FlowFixMe
import styles from './ManageFittersScene.module.scss';
import formatDataForDB from '../../methods/fitters/formatDataForDB';
import NavLocation from '../../components/navLocation';
import FitterSidebar from '../../components/FitterSidebar';
import { find, omit } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAccessibleIcon } from '@fortawesome/free-brands-svg-icons';
import { EditOutlined } from '@ant-design/icons';

const { REACT_APP_SEND_EMAILS } = process.env;

type Props = {
  form: {
    validateFields: (err: any, values: any) => any,
  },
  history: {
    push: (url: string) => void,
    location: Object
  }
};

type State = {
  step: number,
  auth0profile: Object,
  stapletonsData: Object,
  fileList: Object,
  preview: boolean,
  prvImg: string | ArrayBuffer,
  bookingSlotsValue: string,
  openDays: string[],
  bookingsPerSlot: number,
  bookingSlots: Object,
  openingTimes: Object,
  accInfo: Boolean,
  wysiwygKey: String,
  partner: Object,
  isLoading: Boolean,

  fitter: {
    fitterName?: String,
    address1?: String,
    address2?: String,
    address3?: string,
    city?: String,
    county?: String,
    postcode?: string,
    newOpeningTimes?: [],
    slots?: [],
    contactName?: String,
    slotType?: string,
    partnerId?: any,
    stsPrimaryHub?: string,
    offersBookings?: boolean,
    stapletonsID?: String,
    bookingEmail?: {
      value: string,
    },
    bookingContactNumber?: {
      value: string
    },
    googleBusinessUrl: string,
    wheelSizeLimitsInchesDiameter: {
      min: number,
      max: number 
    }
  }
};

class ManageFittersScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auth0profile: {},
      step: 0,
      bookingSlotsValue: 'HOURLY',
      openDays: ['1', '2', '3', '4', '5'],
      bookingsPerSlot: 2,
      bookingSlots: {},
      openingTimes: {},
      weekdays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      fitter: {
        stapletonsID: '',
        bookingEmail: {
          value: ""
        },
        bookingContactNumber: {
          value: ""
        },
        wheelSizeLimitsInchesDiameter: {
          min: "",
          max: ""
        },
        additionalInformation: {
          facilities: [],
          accessibleFacilities: [],
          additionalTyreServices: [],
          additionalServices: [],
          trainedStaff: []
        }
      },
      partner: {},
      fitterList: [],
      activeFitterName: "",
      activeFitterId: "",
      activeTab: "fitterInfo",
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setUpScene();
  }

  setModal = (modalState) => {
    this.setState({
      isLoading: modalState
    })
  }

  getAllFittersForPartner = async (partnerID: string) => {
    this.setModal(true)
    const haveWeGotFittersList = await partnerService.getFitters(partnerID)
    if (haveWeGotFittersList) {
      const { fitters } = haveWeGotFittersList;
      this.setState({
        fitterList: fitters
      });
      this.setModal(false)
    } else {
      this.setModal(false)
      message.error("Error obtaining your list of fitters")
    }
  }

  formatBookingsData = async () =>  {
    const { 
      openingTimes,
      bookingSlotsValue,
      bookingsPerSlot,
      fitter,
      openDays,
      bookingSlots
    } = this.state;
    // formats slots for database
    fitter.slots = await formatDataForDB(
      getDataSource(
        this.toggle,
        openDays,
        bookingSlotsValue,
        bookingSlots,
        bookingsPerSlot,
        openingTimes
      ),
      bookingSlots
    );
    // formats opening times for database
    const newOpeningTimes = [];
    columns.forEach(a => {
      if (a.day) {
        newOpeningTimes.push({
          day: a.dataIndex,
          open: openingTimes[`open${a.dataIndex}`].clone().format('HH:mm'),
          close: openingTimes[`close${a.dataIndex}`].clone().format('HH:mm'),
          isClosed: openDays.indexOf(`${a.dataIndex}`) === -1
        });
      };
    });
    fitter.slotType = bookingSlotsValue;
    fitter.newOpeningTimes = newOpeningTimes;
  }

  handleSubmit = async (e: Event) => {
    e.preventDefault();
    const { fitter } = this.state;
    const isFitterPreexisting = this.checkIfFitterExists(fitter);
    if (isFitterPreexisting) {
        return this.updateFitter()
    } else {
        return this.addNewFitter()
    }
  }


  setUpScene = async () => {

    const { openingTimes } = this.state;
    const auth0profile = await auth0Client.getProfile();
    const auth0ID = auth0profile.sub;
    const showHome = await checkSignUp(auth0ID);
    const partner = showHome[0];
    this.setState({
        partner: partner
    })
    await this.getAllFittersForPartner(partner._id)
    // set default open and closing times
    columns.map(a => {
      if (a.day) {
        openingTimes[`open${a.dataIndex}`] = moment('09:00:00', 'HH:mm:ss');
        openingTimes[`close${a.dataIndex}`] = moment('17:00:00', 'HH:mm:ss');
      }
      return true;
    });
    this.setState({
      stapletonsData: showHome[0],
      auth0profile: auth0profile,
      openingTimes: openingTimes,
    });
    this.setModal(false)
  };

  //funcs to manage fitters in local state and submitting to database

  checkIfFitterExists = (fitter) => {
    const isFitterInFitterList = find(this.state.fitterList, {_id: fitter._id})
    if (isFitterInFitterList === undefined) {
      return false;
    } else {
      return true;
    }
  }

  addNewFitter = async () => {
    const { fitter, partner } = this.state;
    if (verifyFields(fitter, {}, message, 'fitter')) {
    //JPN: user may have based new fitter info on existing fitter, and not cleared data
      await this.formatBookingsData();
      this.setState({activeFitterId: ""});
      const fitterWithExisitingFitterIdRemoved = omit(fitter, ["_id"]);
      fitterWithExisitingFitterIdRemoved.partnerId = partner._id 

      try {
        this.setModal(true)

        const haveWeCreatedFitter = await fitterService.create(fitterWithExisitingFitterIdRemoved);

        if (haveWeCreatedFitter) {
          message.success("fitter added")
          this.clearFitterState();
          this.setModal(false)
          try {
            await this.getAllFittersForPartner(this.state.partner._id);
            this.setState({
              activeTab: 'fitterInfo'
            })

            try {
              await partnerService.createFitterLocationFile(partner._id)
            } catch (error) {
              console.error(error.response.data.error)
            }
            if (REACT_APP_SEND_EMAILS === "true") {
              try {
                await emailService.sendAddedNewFitterEmails(partner, haveWeCreatedFitter.data)
              } catch (error) {
                console.error(error.response.data.error)
              }
            }          

          }catch(error) {
            message.error(`Error retrieving new fitter list: ${error.response.data.error}`)
          }
        } else {
          message.error("Error creating fitter")
        }

      } catch(error) {
        this.setModal(false)
        message.error(`Error creating fitter: ${error.response.data.error}`)
      }
    } else {
      message.error("Please check the format of your information and resubmit")
    }
  }

  updateFitter = async () => {
    const {fitter, partner} = this.state;
    //JPN: check there is a selected existing fitter and update is the right operation
    if (!this.state.activeFitterId) {
      message.error("This fitter already exists, please edit that fitter or give this one a unique name")
    } else {
      if (verifyFields(fitter, {}, message, "fitter")) {
        await this.formatBookingsData()

        try {
          this.setModal(true)
          const haveWeUpdatedFitter = await fitterService.update({
            ...fitter,
            _id: fitter._id
          });

          if (haveWeUpdatedFitter) {
            message.success("Successfully updated fitter")
            await this.getAllFittersForPartner(partner._id)
            this.clearFitterState()
            this.setState({
              activeTab: 'fitterInfo'
            })

            try {
              await partnerService.createFitterLocationFile(partner._id)
            } catch (error) {
              console.error(error.response.data.error)
            }

          } else {
            message.error("Error updating fitter")
          }
        } catch(error) {
          this.setModal(false)
          message.error(`Error updating fitter: ${error.response.data.error}`)
        }
      } else {
        message.error("Please check your information and resubmit")
      }
    }
  }

  setActiveFitter = async (selectedFitterId) => {
    //JPN - This is a working fix for an issue I cannot identify with the function that transforms the opening times and sets them as state. The function works perfectly when the widget is not displayed. I can't uderstand what is happening, as the func recieves the correct data, so it doesn't look like a state issue, though I would guess it's something to do with state/the data source going into the tabbed table component. I've forced the tab change to ensure the reducer func runs correctly.
    this.setState({
      activeTab: 'fitterInfo'
    })
    const { fitterList } = this.state;
    this.setState({
      activeFitterId: selectedFitterId
    })
    //find the new active fitter object with lodash from the fittersList in state array and update state.
    const newActiveFitter = find(fitterList, {_id: selectedFitterId});
    await this.setState({
      fitter: newActiveFitter,
      activeFitterName: newActiveFitter.fitterName
    });

    const newOpeningTimesObject = await getOpeningTimesFromFitterObject(newActiveFitter.newOpeningTimes);
    await this.setState({openingTimes: newOpeningTimesObject})

    const newDays = await getDaysStateFromFitterObject(newActiveFitter.newOpeningTimes, columns)
    this.setState({
      openDays: newDays.openDays,
      weekdays: newDays.weekdays
    })

    await this.setState({bookingSlotsValue: newActiveFitter.slotType})
    await this.setState({bookingsPerSlot: getModeBookingsFromFitterObject(newActiveFitter.slots)})
    const newBookingSlotsFromExisitingFitter = await transformBookingSlotsForTabbedTable(newActiveFitter.slots);
    await this.setState({bookingSlots: newBookingSlotsFromExisitingFitter})
  };

  // called when you toggle a number of bookings per slot
  toggle = async (upOrDown, timeSlot, dayNumber) => {
    //JPN - This was written by the previous team - This block runs when specific individual slots are toggled up or down on specific days.
    const { bookingSlots, bookingsPerSlot } = this.state;
    if (dayNumber) {
      bookingSlots[timeSlot][dayNumber] += upOrDown;
      this.setState(bookingSlots);
    } else {
      //JPN - This was written by prev team - This block runs when the 'main' bookingsPerSlot toggler is clicked up or down
      const val = bookingsPerSlot + upOrDown;
      Object.keys(bookingSlots).map(slot =>
        Object.keys(bookingSlots[slot]).map(v => {
          if (bookingSlots[slot][v] === bookingsPerSlot)
            bookingSlots[slot][v] = val;
          return true;
        })
      );
      this.setState({ bookingsPerSlot: val, bookingSlots });
    }
  };

  handleInput = (event, ob, name, anotherLayer) => {
    const value = event.target.value;
    // eslint-disable-next-line react/destructuring-assignment
    const val = this.state[ob];
    const valDeeper = this.state[ob][name]
    if (anotherLayer) {
      this.setState({
        [`${ob}`]: { ...val, [`${name}`]: {...valDeeper, [`${anotherLayer}`]: value} }
      });
    } else {
      this.setState({ [`${ob}`]: { ...val, [`${name}`]: value } });
    }
  };

  handleChange = (value: string[], checkBoxGroup: string) => {
    const { fitter } = this.state;
    let fitterNewData = fitter.additionalInformation;
    fitterNewData[`${checkBoxGroup}`] = value;

    this.setState({
      fitter: { ...fitter, additionalInformation: fitterNewData}
    })
  };

  clearFitterState = async () => {
    this.setState({
      fitter: {
        fitterName: "",
        address1: "",
        address2: "",
        address3: "",
        city: "",
        county: "",
        postcode: "",
        bookingEmail:{
          value: ""
        },
        bookingContactNumber: {
          value: ""
        },
        googleBusinessUrl: "",
        stapletonsID: '',
        wheelSizeLimitsInchesDiameter: {
          min: "",
          max: "",
        },
        contactName: "",
        additionalInformation: {
          facilities: [],
          accessibleFacilities: [],
          additionalTyreServices: [],
          additionalServices: [],
          trainedStaff: []
        }
      },
      activeFitterName: "",
      activeFitterId: "",
      weekdays: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      bookingSlotsValue: 'HOURLY',
      openDays: ['1', '2', '3', '4', '5'],
      bookingsPerSlot: 2,
      bookingSlots: {},
      openingTimes: {},
    })

    const { openingTimes } = this.state;

    columns.map(a => {
      if (a.day) {
        openingTimes[`open${a.dataIndex}`] = moment('09:00:00', 'HH:mm:ss');
        openingTimes[`close${a.dataIndex}`] = moment('17:00:00', 'HH:mm:ss');
      }
      return true;
    });
    this.setState({
      openingTimes: openingTimes,
    });
  }

  populateFitterStateFromPartner = () => {
    const { partner } = this.state;
    this.clearFitterState();
    this.setState({
      fitter: {
        fitterName: "",
        address1: partner.address1,
        address2: partner.address2,
        address3: partner.address3,
        county: partner.county,
        city: partner.city,
        postcode: partner.postcode,
        bookingEmail: {
          value: partner.contactEmail,
        },
        bookingContactNumber: {
          value: partner.phoneNumber,
        },
        contactName: partner.contactName,
        stapletonsID: partner.stapletonsID,
        wheelSizeLimitsInchesDiameter: {
          min: "",
          max: "",
        },
        additionalInformation: {
          facilities: [],
          accessibleFacilities: [],
          additionalTyreServices: [],
          additionalServices: [],
          trainedStaff: []
        }
      }
    })
  }

  //below are methods for managing the booking components and state
  selectedDays = async value => {
    const openDayValues = await value
      .map(a => columns.map(p => p.day === a && p.dataIndex).filter(Boolean))
      .map(String);
    this.setState({ 
      openDays: openDayValues,
      weekdays: value
     });
  };

  selectedRadio = async value => {
    this.setState({ bookingSlotsValue: await value });
  }


  // reconfigureBookingSlotsOnTimeChange = (openingTimes, bookingSlots, bookingSlotsValue) => {
  //   const dayIndexStrings = ["1", "2", "3", "4", "5", "6", "0", "8"];

  //   const checkForHalfHour = (timeString) => {
  //     //JPN - Check if this time is a half hour as format would be "09:30:00"
  //     return timeString.charAt(4) === "3"
  //   }

  //   const generateFirstSlotKey = (time) => {
  //     //JPN - generates a 'clean' moment with no mutation from the key time
  //     const cleanStart = moment(time._i, 'HH:mm');
  //     if (checkForHalfHour(cleanStart._i)) {
  //       const slotEnd = moment(cleanStart).add(30, 'minutes').format('HH:mm')
  //       return `${cleanStart.format('HH:mm')} -  ${slotEnd}`
  //     } else {
  //       const slotEnd = moment(cleanStart).add(1, 'hours').format('HH:mm')
  //       return `${cleanStart.format('HH:mm')} -  ${slotEnd}`
  //     }
  //   }

  //   const generateFinalSlotKey = (time) => {
  //     //JPN - generates a 'clean' moment with no mutation from the key time
  //     const cleanStart = moment(time._i, 'HH:mm');
  //     if (checkForHalfHour(cleanStart._i)) {
  //       const slotStart = moment(cleanStart).subtract(30, 'minutes').format('HH:mm')
  //       return `${slotStart} -  ${cleanStart.format('HH:mm')}`
  //     } else {
  //       const slotStart = moment(cleanStart).subtract(1, 'hours').format('HH:mm')
  //       return `${slotStart} -  ${cleanStart.format('HH:mm')}`
  //     }
  //   }

  //   const generateAMSlotKey = (time) => {
  //     //JPN - generates a 'clean' moment with no mutation from the key time
  //     const cleanStart = moment(time._i, 'HH:mm');
  //     return `${cleanStart.format('HH:mm')} -  12:00`
  //   }

  //   const generatePMSlotKey = (time) => {
  //     //JPN - generates a 'clean' moment with no mutation from the key time
  //     const cleanStart = moment(time._i, 'HH:mm');
  //     return `12:00 -  ${cleanStart.format('HH:mm')}`
  //   }

  //   console.log("reconfigure openingTimes", openingTimes)
  //   console.log('reconfigure bookingSlots', bookingSlots)
  //   console.log("reconfigure bookingSlotsValue", bookingSlotsValue)

  //   if (bookingSlotsValue === 'HOURLY') {
  //     dayIndexStrings.forEach(dayIndexNumber => {
  //       const comparisonArray = [];
  //       const openTime = openingTimes[`open${dayIndexNumber}`]
  //       const closeTime = openingTimes[`close${dayIndexNumber}`]
  //       console.log("dayIndexNum", dayIndexNumber)
  //       console.log("opens:",openTime)
  //       console.log('closes:', closeTime)

  //       console.log('firstSlot',generateFirstSlotKey(openTime))
  //       console.log('lastSlot',generateFinalSlotKey(closeTime))

  //       //NEED CODE IF ONLY ONE SLOT
  //     }) 
  //   } else if (bookingSlotsValue === 'AM/PM') {
  //     dayIndexStrings.forEach(dayIndexNumber => {
  //       const comparisonArray = [];
  //       const openTime = openingTimes[`open${dayIndexNumber}`]
  //       const closeTime = openingTimes[`close${dayIndexNumber}`]
  //       console.log("dayIndexNum", dayIndexNumber)
  //       console.log("opens:",openTime)
  //       console.log('closes:', closeTime)
  //       console.log("AM slot", generateAMSlotKey(openTime))
  //       console.log("PM slotkey", generatePMSlotKey(closeTime))
  //     }) 
  //   }
  //   return "cat";
  // }


  updateTime = (dayIndex, openTime, closingTime) => {
    const { openingTimes } = this.state;
    openingTimes[`open${dayIndex}`] = openTime;
    openingTimes[`close${dayIndex}`] = closingTime;
    this.setState({ openingTimes });
    // const newBookingSlots = this.reconfigureBookingSlotsOnTimeChange(openingTimes,bookingSlots, bookingSlotsValue)
    // console.log(newBookingSlots)
  };

  generateSidebar = () => {
    const {fitterList, partner, activeFitterId} = this.state  ;

    if (fitterList.length > 0 && partner) {
      return <FitterSidebar
        partner={partner}
        activeFitterIdentifier={activeFitterId}
        matchParameter="_id"
        fitterList={fitterList}
        functionOnSelectFitter={e => this.setActiveFitter(e)}
        />
    }
  };

  getCurrentFitterJSX = () => {
    let currentFitterName = this.state.activeFitterName;
    if (currentFitterName === "") {
      return 'New fitter'
    } else {
      return (<>{currentFitterName} | {this.state.fitter.postcode.toUpperCase()}</>)
    }
  };

  setActiveTab = (newActiveTab) => {
    this.setState({
      activeTab: newActiveTab
    })
  };

  getJourneyButtonText = (activeTab) => {
    if (activeTab === "fitterInfo" || activeTab === "fitterBookings") {
      return "Next";
    } else {
      return "Submit"
    }
  }

  changeTab = (e: Event, targetTab)=> {
    const { fitter, activeTab } = this.state;
    switch (activeTab) {
      case "fitterInfo" :
        if(verifyFields(fitter,{},message,"fitter")) {
          this.setState({activeTab: targetTab});
        }
        break;
      case "fitterBookings" :
      case "fitterAdditionalInfo" :
        this.setState({activeTab: targetTab});
        break;
      default:
        break;
    }
  }

  checkStepAndProceed = (e: Event, activeTab) => {
    const { fitter } = this.state
    if (activeTab === "fitterInfo" ) {
      if (verifyFields(fitter, {}, message, "fitter")) {
        this.setState({
          activeTab: "fitterBookings"
        })
        window.scrollTo(0, 0)
      }
    } else if (activeTab === "fitterBookings") {
      this.setState({
        activeTab: "fitterAdditionalInfo"
      })
      window.scrollTo(0, 0)

    } else if (activeTab === "fitterAdditionalInfo") {
      this.handleSubmitFitter(e);
      setTimeout(() => {
        this.setState({
          showProceedModal: true
        })
      }, 1500)
    }
  }

  handleSubmitFitter = async (e: Event) => {
    e.preventDefault();
    const { fitter } = this.state;
    const isFitterPreexisting = this.checkIfFitterExists(fitter);
    if (isFitterPreexisting) {
        return this.updateFitter()
    } else {
        return this.addNewFitter()
    }
  }

  getActiveIcon = (tabTitle) => {
    return tabTitle === this.state.activeTab ? <EditOutlined /> : null;
  }

  getTabStyles = (tabTitle) => {
    return tabTitle === this.state.activeTab ? styles.activeTab : styles.tab;
  }

  disableStapletonsId = () => {
    const currentFitter = this.state.activeFitterId;
    return currentFitter === '' ? '' : 'disable'
  }

  fitterStapletonIdToolTip = () => {
    const currentFitter = this.state.activeFitterId;
    return currentFitter === '' ? 'Individual fitters stapletons ID' : 'If you need to change this please contact us directly'
  }
  render() {
    const { stapletonsData, activeTab } = this.state;
    const { openDays, bookingsPerSlot, bookingSlotsValue, weekdays } = this.state;
    const { bookingSlots, fitter, openingTimes, isLoading } = this.state;
    const fitterInfoJSX = 
      <div>
        <h2 className={styles.tabTitle}>Fitting centre information</h2>
        <div className={styles.buttonFlex}>
          <div className={styles.fitterButton}>
              <Button className={'rebrand-primary'} onClick={() => this.clearFitterState()}>Start new fitter</Button>
              <Tooltip placement="bottom" targetId="newFitterButton" tooltipText="Use this button to clear information and create a new fitter from scratch" >
                <div>
                  <InfoCircle />
                </div>
              </Tooltip>
          </div>
          <div className={styles.fitterButton}>
              <Button className={'rebrand-primary'} onClick={() => this.populateFitterStateFromPartner()}>Use partner info</Button>
              <Tooltip placement="bottom" targetId="newFitterFromPartnerButton" tooltipText="Use this button to clear information and create a new fitter prefilling information with your business details" >
                <div>
                  <InfoCircle />
                </div>
              </Tooltip>
          </div>
        </div>
        <Row>
          <Label className={styles.labelTitle}>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Fitting centre name (MUST BE UNIQUE)</p>
              <Tooltip placement="bottom" targetId="fitterName" tooltipText="Unique identifying name for this fitting centre (e.g. 'Terry's Tyres, Reading Branch'; mandatory)">
                <InfoCircle />
              </Tooltip>
            </div>
            <Input
              size="large"
              placeholder="Fitter name"
              value={fitter.fitterName}
              onChange={e => this.handleInput(e, 'fitter', 'fitterName')}
            />
          </Label>
        </Row>
        <Label className={styles.labelTitle}>Fitting centre address</Label>
        <Row style={{ paddingTop: '20px' }}>
          <div className={styles.tooltipLabelBox}>
            <p className={styles.tooltipLabel}>Address line 1*</p>
            <Tooltip placement="bottom" targetId="address1" tooltipText="Your business address (mandatory)">
              <InfoCircle />
            </Tooltip>
          </div>
          <Input
            size="large"
            placeholder="123 Your Road"
            value={fitter.address1}
            onChange={e => this.handleInput(e, 'fitter', 'address1')}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <div className={styles.tooltipLabelBox}>
            <p className={styles.tooltipLabel}>Address line 2*</p>
            <Tooltip placement="bottom" targetId="address2" tooltipText="Your business address (mandatory)">
              <InfoCircle />
            </Tooltip>
          </div>
          <Input
            size="large"
            placeholder="Your Area"
            value={fitter.address2}
            onChange={e => this.handleInput(e, 'fitter', 'address2')}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <div className={styles.tooltipLabelBox}>
            <p className={styles.tooltipLabel}>Address line 3</p>
            <Tooltip placement="bottom" targetId="address3" tooltipText="Your business address">
              <InfoCircle />
            </Tooltip>
          </div>
          <Input
            size="large"
            placeholder=""
            value={fitter.address3}
            onChange={e => this.handleInput(e, 'fitter', 'address3')}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <div className={styles.tooltipLabelBox}>
            <p className={styles.tooltipLabel}>City*</p>
            <Tooltip placement="bottom" targetId="addressCity" tooltipText="Your business address (mandatory)">
              <InfoCircle />
            </Tooltip>
          </div>
          <Input
            size="large"
            placeholder="Your city"
            value={fitter.city}
            onChange={e => this.handleInput(e, 'fitter', 'city')}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <div className={styles.tooltipLabelBox}>
            <p className={styles.tooltipLabel}>County*</p>
            <Tooltip placement="bottom" targetId="addressCounty" tooltipText="Your business address (mandatory)">
              <InfoCircle />
            </Tooltip>
          </div>
          <Input
            size="large"
            placeholder="Your County"
            value={fitter.county}
            onChange={e => this.handleInput(e, 'fitter', 'county')}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <div className={styles.tooltipLabelBox}>
            <p className={styles.tooltipLabel}>Postcode*</p>
            <Tooltip placement="bottom" targetId="postcode" tooltipText="Your business postcode (mandatory, standard UK format)">
              <InfoCircle />
            </Tooltip>
          </div>
          <Input
            size="large"
            placeholder="AB12CD"
            value={fitter.postcode}
            onChange={e => this.handleInput(e, 'fitter', 'postcode')}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Email address to receive booking notifications*</p>
              <Tooltip placement="bottom" targetId="bookingEmail" tooltipText="Email address for bookings for this fitter (standard email format, mandatory)">
                <InfoCircle />
              </Tooltip>
            </div>
            <Input
              size="large"
              value={fitter.bookingEmail.value}
              placeholder="email@email.com"
              onChange={e =>
                this.handleInput(e, 'fitter', 'bookingEmail', 'value')
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Fitting centre phone number*</p>
              <Tooltip placement="bottom" targetId="bookingPhone" tooltipText="UK format phone number for bookings for this fitter (mandatory)">
                <InfoCircle />
              </Tooltip>
            </div>
            <Input
              size="large"
              value={fitter.bookingContactNumber.value}
              placeholder="01189000000"
              onChange={e =>
                this.handleInput(
                  e,
                  'fitter',
                  'bookingContactNumber',
                  'value'
                )
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Fitting centre contact name*</p>
              <Tooltip placement="bottom" targetId="constactName" tooltipText="Name of contact for booking enquiries for this fitter (mandatory)">
                <InfoCircle />
              </Tooltip>
            </div>
            <Input
              size="large"
              value={fitter.contactName}
              placeholder="Contact name"
              onChange={e =>
                this.handleInput(
                  e,
                  'fitter',
                  'contactName'
                )
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Stapletons ID*</p>
              <Tooltip placement="bottom" targetId="stapletonsNo" tooltipText={this.fitterStapletonIdToolTip()} >
                <InfoCircle />
              </Tooltip>
            </div>
            <Input
              size="large"
              value={fitter.stapletonsID}
              placeholder="Stapletons ID"
              onChange={e =>
                this.handleInput(
                  e,
                  'fitter',
                  'stapletonsID'
                )
              }
              disabled={this.disableStapletonsId()}
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <h2>Google business profile</h2>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>If you have a google business profile, please provide a URL link below (optional)</p>
              <Tooltip placement="bottom" targetId="googleBusinessURL" tooltipText="Example link format: https://mybusinessurl.com">
                <InfoCircle />
              </Tooltip>
            </div>
            <Input
              size="large"
              placeholder="google business URL"
              value={fitter.googleBusinessUrl}
              onChange={e =>
                this.handleInput(e, 'fitter', 'googleBusinessUrl')
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <h2>Wheel size capacity</h2>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Minimum diameter of wheel you can fit (inches)*</p>
            </div>
            <Input
              size="large"
              placeholder="Min diameter in inches"
              value={fitter.wheelSizeLimitsInchesDiameter.min}
              onChange={e =>
                this.handleInput(e, 'fitter', 'wheelSizeLimitsInchesDiameter', 'min')
              }
            />
          </Label>
        </Row>
        <Row style={{ paddingTop: '20px' }} className={styles.smaller}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>Maximum diameter of wheel you can fit (inches)*</p>
            </div>
            <Input
              size="large"
              placeholder="Max diameter in inches"
              value={fitter.wheelSizeLimitsInchesDiameter.max}
              onChange={e =>
                this.handleInput(e, 'fitter', 'wheelSizeLimitsInchesDiameter', 'max')
              }
            />
          </Label>
        </Row>
      </div>
  
    const fitterBookingsJSX = 
      <div>
        <h2 className={styles.tabTitle}>Opening times and booking slots</h2>
        <Row style={{ paddingTop: '20px' }}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>What days are your fitting centre open?</p>
              <Tooltip placement="bottom" targetId="openingDays" tooltipText="Days this fitting centre is open for business. Please select all that apply (Mandatory).">
                <InfoCircle />
              </Tooltip>
            </div>
          </Label>
          <CheckBoxGroup
            options={columns.map(a => a.day && a.day).filter(Boolean)}
            large
            initialValues={weekdays}
            handleChange={e => this.selectedDays(e)}
          />
        </Row>
        <Row style={{ paddingTop: '20px' }}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>How does your fitting centre take bookings?*</p>
              <Tooltip placement="bottom" targetId="bookingSlotsValue" tooltipText="How do this fitter arrange its booking slots? (Select one only, mandatory)">
                <InfoCircle />
              </Tooltip>
            </div>
          </Label>
          <RadioGroup
            options={['HOURLY', 'AM/PM']}
            large
            handleChange={this.selectedRadio}
            initialValue={bookingSlotsValue}
          />
        </Row>
        <Row style={{ padding: '30px 0' }}>
          <Label>
            <div className={styles.tooltipLabelBox}>
              <p className={styles.tooltipLabel}>How many bookings can you take per slot?</p>
              <Tooltip placement="bottom" targetId="bookingsPerSlot" tooltipText="E.g. You may offer bookings on an hourly basis, but be able to perform fittings for two customers in a single hour slot (mandatory)">
                <InfoCircle />
              </Tooltip>
            </div>
            {bookingSlot(bookingsPerSlot, this.toggle, 'bookingsPerSlot')}
          </Label>
        </Row>
        <h2 style={{ paddingTop: '40px' }}>
          Check and customise your opening and booking slots
        </h2>
        <TabbedTable
          openingTimes={openingTimes}
          dataSource={getDataSource(
            this.toggle,
            openDays,
            bookingSlotsValue,
            bookingSlots,
            bookingsPerSlot,
            openingTimes
          )}
          bookingSlots={bookingSlots}
          openDays={openDays}
          updateTime={this.updateTime}
        />
      </div>
    

    const fitterAdditionalInfoJSX = 
        <div className={styles.additionalInfoForm}>
          <h2 className={styles.tabTitle}>About your premises</h2>
          <Row>
            <Label>
              <div className={styles.tooltipLabelBox}>
                <p className={styles.tooltipLabel}>What facilities do you provide to customers at your fitting centre?</p>
                <Tooltip placement="bottom" targetId="facilities" tooltipText="Please choose all that apply">
                  <InfoCircle />
                </Tooltip>
              </div>
            </Label>
            <CheckBoxGroup
              options={[
                'Off street parking',
                'Toilets',
                'Reception/waiting area',
                'Hot/cold drinks',
                'Free wifi',
                'Workshop viewing window',
                'TV',
                'Snacks or vending machine',
                'Magazines',
                'Baby changing facilities'
              ]}
              large
              handleChange={e => this.handleChange(e, 'facilities')}
              initialValues={fitter.additionalInformation.facilities}
            />
          </Row>
          <Row style={{ padding: '40px 0' }}>
            <Label>
              <div className={styles.tooltipLabelBox}>
                <p className={styles.tooltipLabel}>What <FontAwesomeIcon icon={faAccessibleIcon} /> accessible facilities do you provide to customers?</p>
                <Tooltip placement="bottom" targetId="accessibility" tooltipText="Please choose all that apply">
                  <InfoCircle />
                </Tooltip>
              </div>
            </Label>
            <CheckBoxGroup
              options={[
                'Accessible parking',
                'Accessible toilets',
                'Accessible reception'
              ]}
              large
              handleChange={e =>
                this.handleChange(e, 'accessibleFacilities')
              }
              initialValues={fitter.additionalInformation.accessibleFacilities}
            />
          </Row>
          <Row style={{ padding: '40px 0' }}>
            <h2>Tyre services</h2>
            <Label>
              <div className={styles.tooltipLabelBox}>
                <p className={styles.tooltipLabel}>What additional tyre services do you offer?</p>
                <Tooltip placement="bottom" targetId="additionalTyre" tooltipText="Please choose all that apply">
                  <InfoCircle />
                </Tooltip>
              </div>
            </Label>
            <CheckBoxGroup
              options={[
                'Wheel rotation',
                'ADAS calibration',
                'Nitrogen filling',
                'Fit motorbike tyres',
                'TPMS Service/replacement',
                'Electronic wheel alignment'
              ]}
              large
              handleChange={e =>
                this.handleChange(e, 'additionalTyreServices')
              }
              initialValues={fitter.additionalInformation.additionalTyreServices}
            />
          </Row>

          <Row style={{ padding: '40px 0' }}>
            <h2>Additional services</h2>
            <Label>
              <div className={styles.tooltipLabelBox}>
                <p className={styles.tooltipLabel}>What additional car services do you offer?</p>
                <Tooltip placement="bottom" targetId="additional" tooltipText="Please choose all that apply">
                  <InfoCircle />
                </Tooltip>
              </div>
            </Label>
            <CheckBoxGroup
              options={[
                'MOT',
                'Servicing',
                'Oil change',
                'Courtesy car',
                'Fuel treatments',
                'Exhaust fitting',
                'Brake check',
                'Brake service',
                'Suspension',
                'Batteries',
                'Air conditioning check',
                'Air conditioning service',
                'Windscreen wiper replacement',
                'Bulb replacement',
                'Valeting',
                'Winter check',
                'Safety check',
                'Alloy wheel service',
                'Hybrid/electric car'
              ]}
              large
              handleChange={e =>
                this.handleChange(e, 'additionalServices')
              }
              initialValues={fitter.additionalInformation.additionalServices}
            />
          </Row>
          <Row style={{ padding: '40px 0' }}>
            <h2>Membership and qualifications</h2>
            <Label>
              <div className={styles.tooltipLabelBox}>
                <p className={styles.tooltipLabel}>Do you employ accredited and trained staff?</p>
                <Tooltip placement="bottom" targetId="staff" tooltipText="Please choose all that apply">
                  <InfoCircle />
                </Tooltip>
              </div>
            </Label>
            <CheckBoxGroup
              options={[
                'Tyresafe',
                'Pirelli Performance Centre',
                'Michelin Certified Centre',
                "'Foxy Lady' approved",
                'Good Garage Scheme',
                'Retail Motor Industry (RMI)',
                'Motor Codes',
                'Motor Ombudsman',
                'Tyre Industry Federation',
                'Tyre Recovery Association',
                'National Tyre Distributor Assoc.',
                'Scottish Motor Trade Assoc.',
              ]}
              large
              handleChange={e => this.handleChange(e, 'trainedStaff')}
              initialValues={fitter.additionalInformation.trainedStaff}
            />
          </Row>
        </div>

    return (
      <div>
        {NavLocation("Your fitting centres", 'signedUp')}
        <div className={styles.container}>
          {stapletonsData ? (
            <div className={styles.layoutFlex}>
              <div className={styles.sidebarContainer}>
                {this.generateSidebar()}
              </div>
              <Form onSubmit={this.handleSubmit} className={styles.form}>
                <div className={styles.tooltipLabelBox}>
                  <h2 className={styles.tooltipLabel}>Current fitter: {this.getCurrentFitterJSX()}</h2>
                  <div className={styles.activeFitterTooltip}>
                    <Tooltip placement="bottom" targetId="currentFitter" tooltipText="Add new fitters, or edit ones you have already created using the tabs below">
                      <InfoCircle />
                    </Tooltip>
                  </div>
                </div>
                <div className={styles.tabTops}>
                  <div className={this.getTabStyles("fitterInfo")} onClick={(e) => {this.changeTab(e,"fitterInfo")}}>
                    <p className={styles.tabText}>Fitter info</p>
                    {this.getActiveIcon("fitterInfo")}
                  </div>
                  <div className={this.getTabStyles("fitterBookings")} onClick={(e) => {this.changeTab(e,"fitterBookings")}}>
                    <p className={styles.tabText}>Fitter bookings</p>
                    {this.getActiveIcon("fitterBookings")}
                  </div>
                  <div className={this.getTabStyles("fitterAdditionalInfo")} onClick={(e) => {this.changeTab(e,"fitterAdditionalInfo")}}>
                    <p className={styles.tabText}>Fitter facilities</p>
                    {this.getActiveIcon("fitterAdditionalInfo")}
                  </div>
                </div>
                <div className={styles.tabContent}>
                  {activeTab === "fitterInfo" ? fitterInfoJSX : null}
                  {activeTab === "fitterBookings" ? fitterBookingsJSX : null}
                  {activeTab === "fitterAdditionalInfo" ? fitterAdditionalInfoJSX : null}
                </div>
                <div className={styles.buttonFlex}>
                  <div className={styles.fitterButton}>
                    <Button className={'rebrand-primary'} loadingWithWheel={isLoading} loadingText="Submitting" onClick={e => this.checkStepAndProceed(e, activeTab)}>{this.getJourneyButtonText(activeTab)}</Button>
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <LoadingModal isOpen={isLoading} loadingText="Loading your fitters..." />
          )}
        </div>
      </div>
    );
  }
}

export default Form.create()(ManageFittersScene);