import React from 'react';

import auth0Client from '../../Auth';
import checkSignUp from '../../services/checkSignUp';
//$FlowFixMe
// import NavLocation from '../../components/navLocation';

import { Button } from 'antd';
import CheckBoxGroup from '@motokiki/shared/checkBoxGroup';
import LoadingModal from '@motokiki/shared/loadingModal';
import setLocation from '../../methods/setLocation';
import styles from './TermsAndConditionsScene.module.scss';

type Props = {
  history: {
    push: (url: string) => void,
    location: Object
  }
};

type State = {
  auth0profile: Object,
  acceptedBaseTAndCs: Boolean,
  acceptedGeneralTAndCs: Boolean,
  acceptedCustomerCharter: Boolean,
  isLoading: Boolean
};

class TermsAndConditionsScene extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      auth0profile: {},
      acceptedBaseTAndCs: false,
      acceptedGeneralTAndCs: false,
      acceptedCustomerCharter: false,
      step1Complete: false,
      isLoading: true
    };
  }

  componentDidMount() {
    this.setUpPage();
  }

  setUpPage = async () => {
    const { history } = this.props;
    const auth0Profile = await auth0Client.getProfile();
    const auth0ID = auth0Profile && auth0Profile.sub;
    const showHome = auth0ID ? await checkSignUp(auth0ID) : [{}, ''];
    setLocation(history.location.pathname, showHome[1], history);
    this.setState({
      auth0profile: auth0Profile,
      acceptedBaseTAndCs: false,
      acceptedGeneralTAndCs: false,
      acceptedCustomerCharter: false,
      isLoading: false
    });
  };

  generateContent = steps => {
    const {
      acceptedBaseTAndCs,
      acceptedGeneralTAndCs,
      step1Complete
    } = this.state;
    if (acceptedBaseTAndCs && acceptedGeneralTAndCs && step1Complete) {
      return steps[1].content;
    } else {
      return steps[0].content;
    }
  };

  handleCheckBoxCheck = (value: string[]) => {
    value.forEach(item => {
      const currentStateOfItem = this.state[item];
      this.setState({
        [item]: !currentStateOfItem
      });
    });
  };

  buttonContinue = () => {
    const { acceptedBaseTAndCs, acceptedGeneralTAndCs } = this.state;
    if (acceptedBaseTAndCs && acceptedGeneralTAndCs) {
      this.setState({
        step1Complete: true
      });
    }
  };

  buttonComplete = () => {
    const { history } = this.props;
    history.push('/signUp');
  };

  render() {
    const receivedInfo = true;
    const {
      acceptedBaseTAndCs,
      acceptedGeneralTAndCs,
      acceptedCustomerCharter,
      isLoading
    } = this.state;

    const steps = [
      {
        sectionName: 'tAndCs',
        content: (
          <div key='1'>
            <div>
              <div>
                <p>
                  Once you’ve completed the sign-up process, we’ll verify your
                  account details with Stapleton’s and, subject to status, aim
                  to get you live on Motokiki within 5 days.
                </p>
                <p>
                  Before progressing with your sign up it is important that you
                  understand your obligations under the agreement.
                </p>
                <p>
                  Please take a moment to read the following and if you wish to
                  proceed click on the I agree button below.
                </p>
                <h3>The Contract:</h3>
                <p>For the purpose of this contract, the terms:</p>
                <ul>
                  <li>
                    "The Website" shall mean the Supplier's website bearing the
                    web address www.motokiki.com.
                  </li>
                  <li>
                    “Customer” shall mean any person or entity who browses the
                    Website who may purchase tyres.
                  </li>
                  <li>
                    “Fitting Centre” shall mean the location and/or retailer
                    chosen by the Customer from the options available on the
                    Website as the location where tyres are to be fitted.
                  </li>
                  <li>
                    "The Client" means the person who purchases the Services
                    from the Supplier and whose details are set out in The
                    Contract
                  </li>
                  <li>“The Supplier” means Motokiki Ltd T/A Motokiki</li>
                </ul>
              </div>
              <div className={styles.scrollBox}>
                <div className={styles.scrollBoxContent}>
                  <h3>Your (Client) Obligations</h3>
                  <ol>
                    <li>
                      The Client must have and maintain a credit account with
                      Stapleton’s Tyre Services (STS) of sufficient amount to
                      ensure the Client can provide an efficient and prompt
                      service to Customers. The Client must maintain an active
                      B2B tyre ordering portal (Profitlink) account with STS.
                    </li>
                    <li>
                      When a Customer purchases a tyre using the Website, the
                      tyre/s will be despatched by STS to the Customer’s choice
                      of Client and Fitting Centre. STS will invoice the Client
                      for the tyre/tyres at point of delivery.
                    </li>
                    <li>
                      To facilitate the provision of information to Customers
                      the Client must supply to the Supplier all the information
                      the Supplier may reasonably request and in particular but
                      not by way of limitation information about the Client’s
                      Fitting Centres, opening hours, Customer facilities, work
                      schedules and diaries.
                    </li>
                    <li>
                      The Client will set the selling price for the tyres they
                      wish to supply on the STS B2B tyre ordering portal
                      PROFITLINK or any other such method that the Supplier may
                      agree. The Client agrees to allow the Supplier to order
                      tyres on its behalf via the STS tyre ordering portal
                      platform,{' '}
                      <span className={styles.highlightedCondition}>
                        Profitlink
                      </span>
                      , when a Customer selects the Client as a Fitting Centre
                    </li>
                    <li>
                      The Client is responsible for ensuring that goods and
                      services purchased by a Customer are supplied at the
                      location and time quoted by the Client to the Customer
                      provided that if there are any changes in the information
                      given to the Customer, the Customer must be informed as
                      soon as reasonably possible and alternative arrangements
                      convenient to the Customer must be made.
                    </li>
                    <li>
                      The Client will not bid on Motokiki brand search terms on
                      any cost per click search engine or other search engine,
                      search marketing platform or social media platform in
                      which listing order or display is determined by payment to
                      the search engine or other third party.
                    </li>
                    <li>
                      In the event that the goods or services purchased by a
                      Customer are not suitable the Client will provide
                      sufficient information to Motokiki to determine whether
                      the inability to fit the selected tyre/s was as a result
                      of:
                      <ol type='a'>
                        <li>
                          ) A Customer selection error or other customer related
                          issue.
                        </li>
                        <li>) An error with the supply of tyre/s from STS.</li>
                        <li>) A Motokiki error.</li>
                      </ol>
                    </li>
                    <li>
                      The Client may terminate the Contract at any time by
                      giving not less than four weeks’ notice in writing to
                      Motokiki. Termination or expiry of the Contract shall not
                      affect any accrued rights and liabilities of the Client at
                      any time up to the date of termination. Motokiki may
                      terminate the Contract at any time by giving not less than
                      four weeks’ notice in writing to the Client. Termination
                      or expiry of the Contract shall not affect any accrued
                      rights and liabilities of Motokiki at any time up to the
                      date of termination.
                    </li>
                    <li>
                      The Client acknowledges that signing the Order and
                      entering into the Contract constitutes the Client’s
                      authority under Condition 14.6.1 and 14.8 of the{' '}
                      <a
                        className={styles.link}
                        href='https://partnerkiki-static-assets.s3.eu-west-2.amazonaws.com/terms/motokiki_general_terms_and_conditions.pdf'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        General Terms and Conditions
                      </a>{' '}
                      for Motokiki to process and/or transfer or otherwise
                      disclose such Protected Data as Motokiki shall reasonably
                      require.
                    </li>
                    <li>
                      The Client agrees to receive payment for goods and
                      services provided by allowing customers to pay:
                      <ol type='a'>
                        <li>) At point of fitting (Pay-in-Store) and/or:</li>
                        <li>
                          ) Via the Suppliers selected Payment Portal, STRIPE
                          (Pay Online). To do so will require the Client setting
                          up a STRIPE account, if it does not already have one,
                          as part of the sign-up process.
                        </li>
                      </ol>
                    </li>
                    <li className={styles.highlightedCondition}>
                      The Client shall pay (the Acquisition Fee or Price) to
                      Motokiki the sum of 3% + VAT of the basket value whenever
                      a Customer purchases tyres from the Website.
                    </li>
                  </ol>
                  <h3>Our (Motokiki) Obligations</h3>
                  <ol start='12'>
                    <li>
                      Motokiki will maintain the Website in order to provide
                      information to Customers about the goods and services
                      provided by the Client supplied by the STS to the Client.
                    </li>
                    <li>
                      Motokiki will maintain within the Website the following
                      functionality with the aim of providing Customers with the
                      ability to compare the offering of the STS Catalogue from
                      multiple retailers and then to choose and order from
                      Motokiki the goods and services supplied by the Client.
                    </li>
                    <li>
                      Motokiki will maintain a facility within the Website to
                      enable Customers to pay:
                      <ol type='a'>
                        <li>
                          ) At point of fitting (Pay-in-Store). Motokiki will
                          invoice the Client monthly for Acquisistion fees,
                          and/or:
                        </li>
                        <li>
                          ) Online for the goods and services purchased. This
                          Payment Portal will pay the Client the Customers
                          purchase price of goods from the website less the
                          Motokiki Acquisition Fee no later than 7 days after
                          purchase.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Motokiki reserves the right to temporarily remove a Client
                      from the Website where their presence may affect the
                      integrity of the Website. Following investigation and
                      rectification of any issue the Client will be returned to
                      the Website.
                    </li>
                    <li>
                      Motokiki will populate the Website with the information
                      provided by the Client within 5 Business Days of receipt
                      of that information provided that the Client shall
                      indemnify Motokiki against all proceedings claims costs
                      and demands arising out of the information supplied by the
                      Client being inaccurate or misleading in any way.
                    </li>
                  </ol>
                </div>
              </div>
              <div className={styles.acceptButtons}>
                <p>
                  I have read and agree to the contract above with Motokiki
                  including the{' '}
                  <a
                    className={styles.link}
                    href='https://partnerkiki-static-assets.s3.eu-west-2.amazonaws.com/terms/motokiki_general_terms_and_conditions.pdf'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    general terms and conditions
                  </a>
                  .
                </p>
                <div className={styles.buttonFlex}>
                  <div className={styles.buttonBox}>
                    <CheckBoxGroup
                      options={['I Agree']}
                      large
                      handleChange={() =>
                        this.handleCheckBoxCheck([
                          'acceptedBaseTAndCs',
                          'acceptedGeneralTAndCs'
                        ])
                      }
                    />
                  </div>
                  <div className={styles.buttonBox}>
                    <div className={styles.continueButton}>
                      {acceptedBaseTAndCs && acceptedGeneralTAndCs ? (
                        <Button
                          shape='round'
                          size='large'
                          type='mk'
                          className={'rebrand-primary'}
                          onClick={() => this.buttonContinue()}
                        >
                          Continue
                        </Button>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      },
      {
        sectionName: 'customerCharter',
        content: (
          <div key='2'>
            <div className={styles.charterText}>
              <h3>The Motokiki Customer Charter</h3>
              <h4>Our Retail Partners agree they will:</h4>
              <div className={styles.threeValues}>
                <div>
                  <h3>Quality</h3>
                  <ul>
                    <li>Treat each customer fairly and courteously.</li>
                    <li>
                      Ensure the car is inspected by a trained member of staff.
                    </li>
                    <li>Handle each of our customers' cars with care.</li>
                  </ul>
                </div>
                <div>
                  <h3>Honesty</h3>
                  <ul>
                    <li>
                      Advise customers immediately of any delays or
                      complications.
                    </li>
                    <li>Show customers the work has been carried out.</li>
                    <li>
                      Give honest advice on any extra work that might be
                      required.
                    </li>
                  </ul>
                </div>
                <div>
                  <h3>Integrity</h3>
                  <ul>
                    <li>Ensure that the Motokiki price is a great deal</li>
                    <li>
                      Responsibly recycle customers' old tyres with a registered
                      waste carrier.
                    </li>
                    <li>
                      Ensure any customer complains are effectively addressed.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.acceptButtons}>
              <p>
                I have read and agree to the Motokiki Customer Charter above.
              </p>
              <div className={styles.buttonFlex}>
                <div className={styles.buttonBox}>
                  <CheckBoxGroup
                    options={['I Agree']}
                    large
                    handleChange={() =>
                      this.handleCheckBoxCheck(['acceptedCustomerCharter'])
                    }
                  />
                </div>
                <div className={styles.buttonBox}>
                  <div className={styles.continueButton}>
                    {acceptedCustomerCharter ? (
                      <Button
                        type='mk'
                        size='large'
                        shape='round'
                        className={'rebrand-primary'}
                        onClick={() => this.buttonComplete()}
                      >
                        Continue
                      </Button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
    ];

    return (
      <div>
        {/* {NavLocation('Motokiki Partner Portal: Terms and Conditions')} */}
        <div>
          {receivedInfo ? (
            <>
              <div className={styles.mainBanner}>
                <h2>Welcome to the Motokiki Partner Portal</h2>
                <h3>
                  You're on your way to joining Motokiki as a new partner!
                </h3>
              </div>
              <div className={styles.containerShell}>
                {this.generateContent(steps)}
              </div>
            </>
          ) : (
            <LoadingModal
              isOpen={isLoading}
              loadingText='Preparing your journey...'
            />
          )}
        </div>
      </div>
    );
  }
}

export default TermsAndConditionsScene;
