/* @flow */
import { message } from 'antd';

import api from './api';
import auth0Client from '../Auth';

const createNewStripe = {
  createNewStripe: async (code: string): Promise<Object> => {
    if (!code) {
      throw new Error('Missing stripe account code!');
    }

    try {
      const { sub } = await auth0Client.getProfile();
      const res = await api.post(`/stripe/addStripe`, {
        code,
        auth0ID: sub
      });
      return res;
    } catch (error) {
      message.error('Error adding new stripe connection');
      console.error('e', error.response);
      throw (error);
    }
  }
};

export default createNewStripe;
