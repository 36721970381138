import auth0Client from '../Auth';

const setLocation = (currentLocation, showHome, history) => {
  if (
    `/${showHome}` === `${currentLocation}` ||
    (showHome === 'signedUp' && `/${currentLocation}` === `/`)
  ) {
  } else if (showHome === 'signedUp') {
    history.push(`/`);
  } else if (showHome === 'signedUp-differentAuth0Account') {
    auth0Client.signOut();
  } else {
    history.push(`/${showHome}`);
  }
};

export default setLocation;