import React from "react";
import styles from "./FitterCardFront.module.scss";

const FitterCardFront = ({image, fitter}) => {
  const { fitterName, address1, postcode } = fitter;

  return (
    <section className={styles.FitterCardFront}>
      <div className={styles.text}>
        <p>{fitterName}</p>
        <p>{address1}</p>
        <p>{postcode.toUpperCase()}</p>
      </div>
      {image !== "unset" ? (<div >
        <img src={`${image}?v=${new Date()}`} className={styles.logo} alt="logo"/>
      </div>) : null}
    </section>
  );
};

export default FitterCardFront;