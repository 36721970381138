/* @flow */
import { message } from 'antd';

import api from './api';

const emails = {
  sendInitialSignUpEmails: async (partnerID: String): Promise<Object> => {
    if (!partnerID) {
      throw new Error('Missing partner id');
    }
    try {
      const res = await api.get(`/emails/sendInitialSignUpEmails/${partnerID}`);
      return res;
    } catch (error) {
      message.error('Error sending emails');
      console.error('e', error.response);
      throw (error);
    }
  },
  sendAddedNewFitterEmails: async (partner: String, fitter: String): Promise<Object> => {
    if (!partner || !fitter) {
      throw new Error('Missing partner or fitter data');
    }
    try {
      const res = await api.post(`/emails/sendAddedNewFitterEmails/`, { 
        partner: partner,
        fitter: fitter
      });
      return res;
    } catch (error) {
      message.error('Error sending emails');
      console.error('e', error.response);
      throw (error);
    }
  }
};



export default emails;