/* @flow */
import { message } from 'antd';

import api from './api';

const fitter = {
  create: async (data: any): Promise<Object> => {
    if (!data) {
      throw new Error('Missing data');
    }
    try {
      const res = await api.post(`/fitter`, {
        data
      });
      return res;
    } catch (error) {
      message.error('Error adding fitter');
      console.error('e', error.response);
      throw (error);
    }
  },
  update: async (values: any): Promise<Object> => {
    if (!values) {
      throw new Error('Missing values');
    } else if (!values._id) {
      throw new Error('Missing ID')
    }

    const fitterID = values._id;

    const data = {
      values
    };
    try {
      const res = await api.put(`/fitter/${fitterID}`, {
        data
      });
      return res;
    } catch (error) {
      message.error('Error updating fitter');
      console.error('e', error.response);
      throw (error);
    }
  }
};

export default fitter;